import { Fragment } from "react";

import styles from "./index.module.css";
import { AppTranslations } from "./types";

export const frenchTranslations: AppTranslations = {
  aboutUs: {
    commonDescription: (
      <Fragment>
        {`Nous nous sommes rencontrés en école d'ingénieur à Grenoble, où 
      nous avons pu croiser nos passions. Puis nous sommes montés à Paris 
      pour travailler avant de nous rendre compte qu'on pouvait encore 
      avancer ensemble, dans le respect de nos valeurs. Après avoir fait
      quelques t-shirt pour nous et nos potes, on a pensé à partager notre
      passion avec le reste du monde. C'est comme ça qu'est né `}
        <span className={styles.bold}>T-ART</span>
        {", l'art sur un t-shirt."}
      </Fragment>
    ),
    guillaumeDescription:
      "Né à Strasbourg un peu avant Paul, il a été élevé à la choucroute " +
      "pendant vingt ans. Il aime aussi le bateau, le vélo, sa femme " +
      "(une autre) et la pêche. Il a réalisé des études avant de bosser " +
      "comme développeur.",
    hereIs: "Voici ",
    paulDecription:
      "Né à Montpellier au siècle dernier, il a grandi à Toulouse sous le " +
      "soleil du sud. Il aime le bateau, le vélo, sa femme et son boulot. " +
      "Il a réalisé de brillantes études sans jamais abandonner sa passion " +
      ": le dessin.",
    thisIs: "Voilà ",
    whoAreWe: "Qui sommes-nous ?",
  },
  actions: {
    confirmEmptyCart: "Voulez-vous vraiment vider votre panier ?",
    continueShopping: "Reprendre vos achats",
    emptyCartDetails: "Vous allez perdre tous les articles du panier.",
    emptyTheCart: "Vider le panier",
    pay: "Payer",
  },
  addFirstPhoto: {
    addPhoto: "Ajouter une photo",
  },
  addNewItem: {
    addItem: "Ajouter un produit",
    myItems: "Mes produits",
  },
  addToCart: {
    addToCart: "Ajouter au panier",
    continueShopping: "Continuer vos achats",
    goToCart: "Accéder au panier",
    itemAddedToCart: "Article ajouté au panier !",
  },
  backToPage: {
    backToPreviousPage: "Retour à la page précédente",
  },
  cart: {
    title: "Mon panier",
  },
  checkoutForm: {
    isSavingPaymentPreferences: "Sauvegarder les adresses de paiement",
  },
  common: {
    availableSizes: "Tailles disponibles",
    backToDashboard: "Menu principal",
    columns: <Fragment>&nbsp;:&nbsp;</Fragment>,
    date: "Date",
    password: "Mot de passe",
    price: "Prix",
    quantity: "Quantité",
    unitPrice: "Prix unitaire",
    unknownError: (errorDescription) =>
      `Erreur inattendue : ${errorDescription}`,
    username: "Identifiant",
  },
  createItem: {
    addAnother: "Ajouter un autre article",
    addItem: "Ajouter",
    itemCreatedSuccessfully: "Cet article a bien été ajouté !",
    title: "Nouveau produit",
  },
  defaultErrorPage: {
    somethingWentWrong: "Il y a un problème quelque part...",
  },
  emptyCart: {
    emptyCart: "Votre panier est actuellement vide.",
    fillIt: "Par ici pour le remplir !",
  },
  favorites: {
    noFavorites: "Vous n'avez aucun favori.",
    title: "Mes favoris",
  },
  footer: {
    aboutUs: "Qui sommes-nous ?",
    content: "Le footer",
  },
  itemDetails: {
    addItem: "Ajouter",
    back: "Retour",
    name: "Nom du produit",
  },
  items: {
    linePrice: "Prix total",
    name: "Produit",
    size: "Taille",
  },
  login: {
    title: "Connexion",
    unknownUser: "Utilisateur inconnu. Veuillez vous enregistrer.",
    wrongPairUserPassword:
      "Le couple identifiant/mot de passe ne correspond pas.",
  },
  loginFooter: {
    register: "créer un compte",
    youAreNew: "Vous êtes nouveau ?",
  },
  notAdminPage: {
    backHome: "Retour à l'acceuil",
    description: "Cette page est réservée aux administrateurs.",
  },
  notification: {
    cancel: "Annuler",
    confirm: "Confirmer",
  },
  order: {
    backToOrders: "retour aux commandes",
    item: "Produit",
    status: "Statut",
    title: "Commande",
    totalPrice: "Prix total",
  },
  orders: {
    itemsNumber: "Nombre d'articles",
    noOrder: "Vous n'avez aucune commande.",
    title: "Mes commandes",
  },
  pageNotFound: {
    pageNotFound: "Cette page n'existe pas.",
  },
  paymentStepTranslations: {
    BILLING_ADDRESS: "Adresse de facturation",
    CREDIT_CARD: "Paiement",
    EMAIL: "Adresse e-mail",
    missingEmail: "Veuillez remplir l'adresse mail",
    missingFirstNameOrLastName: "Veuillez remplir le nom et le prénom",
    payNow: "Valider le paiement",
    SHIPPING_ADDRESS: "Adresse de livraison",
  },
  paymentSuccess: {
    findYourOrdersHere: "Retrouvez toutes vos commandes ici",
    paymentSuccess: "Votre paiement est validé !",
    yourOrderId: (orderId) => (
      <Fragment>
        {"Votre identifiant de commande est le suivant : "}
        <span className={styles.bold}>{orderId}</span>.
      </Fragment>
    ),
    youWillGetAnEmail:
      "Vous recevrez un mail avec un récapitulatif de vos achats.",
  },
  register: {
    confirmPassword: "Confirmer le mot de passe",
    passwordsDoNotMatch: "Mots de passe non identiques",
    title: "S'inscrire",
    usernameAlreadyTaken:
      "Cet identifiant est déjà pris, veuillez en choisir un autre",
  },
  registerFooter: {
    alreadyRegistered: "Déjà inscrit ?",
    loginHere: "se connecter",
  },
  removeItem: {
    remove: "Supprimer",
    removePopupDescription:
      "Cette opération est irréversible et entraîne la suppression de ses " +
      "données, y compris le stockage de ses images.",
    removePopupTitle: "Voulez-vous vraiment supprimer cet article ?",
  },
  removeLineNotification: {
    content: "Voulez-vous vraiment enlever cet article du panier ?",
  },
  shippingAddress: {
    isSameBillingAddress: "Utiliser la même adresse de facturation",
  },
  totalPrice: {
    totalPrice: "Prix du panier",
  },
  updateButton: {
    close: "Fermer",
    update: "Mettre à jour",
    updatedSuccessfullyDescription: "Article mis à jour avec succès.",
  },
  user: {
    connection: "Connexion",
    logout: "Déconnexion",
  },
};
