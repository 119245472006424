import { Fragment } from "react";

import styles from "./index.module.css";
import { AppTranslations } from "./types";

export const enTranslations: AppTranslations = {
  aboutUs: {
    commonDescription: (
      <Fragment>
        {`We met in an engineering school in Grenoble, 
      where we were able to merge our passions. 
      Then we moved to Paris to work before realizing 
      that we could still progress together, 
      while respecting our values. 
      After making a few T-shirts for ourselves and our friends, 
      we thought about sharing our passion with the rest of the world. 
      That's how `}
        <span className={styles.bold}>T-ART</span>
        {", art on a T-shirt, was born."}
      </Fragment>
    ),
    guillaumeDescription:
      "Born in Strasbourg a little before Paul, " +
      "he was raised on sauerkraut for twenty years. " +
      "He also loves sailing, cycling, his wife (a different one), " +
      "and fishing. " +
      "He completed his studies before working as a developer.",
    hereIs: "Here is ",
    paulDecription:
      "Born in Montpellier in the last century, " +
      "he grew up in Toulouse under the southern sun. " +
      "He loves sailing, cycling, his wife, and his job. " +
      "He pursued a brilliant education " +
      "without ever giving up his passion: drawing.",
    thisIs: "This is ",
    whoAreWe: "Who are we?",
  },
  actions: {
    confirmEmptyCart: "Do you really want to empty the cart?",
    continueShopping: "Back to shopping",
    emptyCartDetails: "All items will be removed.",
    emptyTheCart: "Empty the cart",
    pay: "Pay",
  },
  addFirstPhoto: {
    addPhoto: "Add photo",
  },
  addNewItem: {
    addItem: "Add new item",
    myItems: "My items",
  },
  addToCart: {
    addToCart: "Add to cart",
    continueShopping: "Continue shopping",
    goToCart: "Go to cart",
    itemAddedToCart: "Item added to cart!",
  },
  backToPage: {
    backToPreviousPage: "Go back to previous page",
  },
  cart: {
    title: "My cart",
  },
  checkoutForm: {
    isSavingPaymentPreferences: "Save payment addresses",
  },
  common: {
    availableSizes: "Available sizes",
    backToDashboard: "Back to dashboard",
    columns: <Fragment>:&nbsp;</Fragment>,
    date: "Date",
    password: "Password",
    price: "Price",
    quantity: "Quantity",
    unitPrice: "Unit price",
    unknownError: (errorDescription) =>
      `Unexpected exception: ${errorDescription}`,
    username: "Username",
  },
  createItem: {
    addAnother: "Add another item",
    addItem: "Add item",
    itemCreatedSuccessfully: "Item created successfully!",
    title: "New item",
  },
  defaultErrorPage: {
    somethingWentWrong: "Something went wrong...",
  },
  emptyCart: {
    emptyCart: "The shopping cart is actually empty.",
    fillIt: "Go fill it!",
  },
  favorites: {
    noFavorites: "You have no favorites.",
    title: "My favorites",
  },
  footer: {
    aboutUs: "About us",
    content: "The footer",
  },
  itemDetails: {
    addItem: "Add item",
    back: "Back",
    name: "Name",
  },
  items: {
    linePrice: "Price",
    name: "Item",
    size: "Size",
  },
  login: {
    title: "Login",
    unknownUser: "Unknown user. Please register first.",
    wrongPairUserPassword: "Username and password do not match",
  },
  loginFooter: {
    register: "register",
    youAreNew: "You are new? please",
  },
  notAdminPage: {
    backHome: "Back home",
    description: "This page is only for admins.",
  },
  notification: {
    cancel: "Cancel",
    confirm: "Confirm",
  },
  order: {
    backToOrders: "Back to orders",
    item: "Item",
    status: "Status",
    title: "Order",
    totalPrice: "Total price",
  },
  orders: {
    itemsNumber: "Number of items",
    noOrder: "You have no order.",
    title: "My orders",
  },
  pageNotFound: {
    pageNotFound: "This page does not exist.",
  },
  paymentStepTranslations: {
    BILLING_ADDRESS: "Billing address",
    CREDIT_CARD: "Credit card",
    EMAIL: "E-mail",
    missingEmail: "Email address is missing",
    missingFirstNameOrLastName: "First name or last name is missing",
    payNow: "Pay now",
    SHIPPING_ADDRESS: "Shipping address",
  },
  paymentSuccess: {
    findYourOrdersHere: "Find all your orders here",
    paymentSuccess: "Your payment is successfull!",
    yourOrderId: (orderId) => (
      <Fragment>
        {"Your order id is: "}
        <span className={styles.bold}>{orderId}</span>.
      </Fragment>
    ),
    youWillGetAnEmail: "You will get a recap by email.",
  },
  register: {
    confirmPassword: "Confirm password",
    passwordsDoNotMatch: "Passwords do not match",
    title: "Register",
    usernameAlreadyTaken: "Username already exists. Please find a new one.",
  },
  registerFooter: {
    alreadyRegistered: "Already registered?",
    loginHere: "login here",
  },
  removeItem: {
    remove: "Remove item",
    removePopupDescription:
      "This will delete all its relative data and images.",
    removePopupTitle: "Do you really want to remove this item?",
  },
  removeLineNotification: {
    content: "Do you really want to remove this item from the cart?",
  },
  shippingAddress: {
    isSameBillingAddress: "Use the same address for billing",
  },
  totalPrice: {
    totalPrice: "Cart price",
  },
  updateButton: {
    close: "Close",
    update: "Update",
    updatedSuccessfullyDescription: "Item updated succesfully.",
  },
  user: {
    connection: "Connection",
    logout: "Log out",
  },
};
