import { createContext, FC, ReactNode, useContext } from "react";

import { useParams } from "react-router-dom";

import { getTranslations } from ".";
import { AppTranslations, ILanguage, LANGUAGES } from "./types";
import PageNotFound from "../Routes/ErrorPages/PageNotFound";
import UnknownLanguage from "../Routes/ErrorPages/UnknownLanguage";

const LanguageContext = createContext<
  | {
      language: ILanguage;
    }
  | undefined
>(undefined);

const LanguageContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { language } = useParams();

  if (!language) {
    return <PageNotFound />;
  }

  if (!LANGUAGES.includes(language)) {
    return <UnknownLanguage />;
  }

  return (
    <LanguageContext.Provider value={{ language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useTranslations = (module?: keyof AppTranslations) => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("Context was not initialized");
  }
  return getTranslations({ language: context.language, module });
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("Context was not initialized");
  }
  return context;
};

export default LanguageContextProvider;
