import { FC } from "react";

import { useOutletContext } from "react-router-dom";

import Items from "./Items";
import Dashboard from "../../../Components/Dashboard";
import { IUser } from "../../../types";

const Admin: FC = () => {
  const {
    user: { items },
  } = useOutletContext() as { user: IUser };

  return (
    <Dashboard>
      <Items items={items} />
    </Dashboard>
  );
};

export default Admin;
