import { FC, ReactNode } from "react";

import styles from "./index.module.css";
import Link from "../../Common/Link";

type Props = {
  children?: ReactNode;
  className?: string;
  icon: ReactNode;
  isShowingText?: boolean;
  onClick?: () => void;
  route: string;
  text: string;
};

const getTextClassName = (isShowingText: boolean) => {
  if (isShowingText) {
    return styles.text;
  }
  return `${styles.text} ${styles.hide}`;
};

const HeaderLink: FC<Props> = ({
  className,
  children,
  icon,
  isShowingText = true,
  onClick,
  route,
  text,
}) => {
  return (
    <Link
      buttonType="black"
      className={`${styles.wrapper} ${className}`}
      route={route}
      onClick={onClick}
    >
      {icon}
      <div className={getTextClassName(isShowingText)}>{text}</div>
      {children}
    </Link>
  );
};

export default HeaderLink;
