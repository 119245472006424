import { FC, ReactNode, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import styles from "./index.module.css";
import Button from "../../Components/Common/Button";
import { useUserContext } from "../../contexts/user/Context";
import { useLanguageContext } from "../../translations/Context";
import { ITranslation } from "../../translations/types";
import { ACCESS_TOKEN } from "../../utils/axios";

type Props = {
  children?: ReactNode;
  footer: ReactNode;
  getErrorMessage: (status: number) => ITranslation | undefined;
  isButtonDisabled: boolean;
  onSubmit: () => Promise<{ data: string }>;
  title: ReactNode;
  urlSuffix: string;
};

const Connection: FC<Props> = ({
  children,
  footer,
  getErrorMessage,
  isButtonDisabled,
  onSubmit,
  title,
  urlSuffix,
}) => {
  const navigate = useNavigate();

  const { user, refresh } = useUserContext();
  const { language } = useLanguageContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ITranslation>();

  useEffect(() => {
    if (user) {
      navigate(`/${language}/${urlSuffix}`);
    }
  }, [language, navigate, urlSuffix, user]);

  return (
    <form
      className={styles.wrapper}
      onSubmit={(event) => {
        event.preventDefault();
        setIsFetching(true);
        onSubmit()
          .then((response) => {
            localStorage.setItem(ACCESS_TOKEN, response.data);
            refresh().then(() => navigate(`/${language}/${urlSuffix}`));
          })
          .catch(({ response }) =>
            setErrorMessage(getErrorMessage(response.data.status))
          )
          .finally(() => setIsFetching(false));
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.entries}>{children}</div>
      <Button
        disabled={isButtonDisabled || isFetching}
        isLoading={isFetching}
      >
        {title}
      </Button>
      <div className={styles.footer}>{footer}</div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </form>
  );
};

export default Connection;
