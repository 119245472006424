import { FC, Fragment } from "react";

import CheckOutlined from "@ant-design/icons/CheckOutlined";

import styles from "./index.module.css";
import Button from "../../../../../Components/Common/Button";

type Props = {
  currentStep: number;
  numberOfSteps: number;
  onStepClick: (index: number) => void;
};

const Progression: FC<Props> = ({
  currentStep,
  numberOfSteps,
  onStepClick,
}) => (
  <div className={styles.wrapper}>
    {Array.from(Array(numberOfSteps).keys()).map((step) => {
      if (step < currentStep) {
        return (
          <Fragment key={"step" + step}>
            <Button
              buttonType="text"
              className={styles.step}
              onClick={() => onStepClick(step)}
            >
              <CheckOutlined className={styles.completed} />
            </Button>
            <div className={`${styles.line} ${styles.completedLine}`} />
          </Fragment>
        );
      }
      let stepClassName;
      if (step === currentStep) {
        stepClassName = styles.current;
      } else {
        stepClassName = styles.incoming;
      }
      if (step === numberOfSteps - 1) {
        return (
          <div
            className={`${styles.step} ${stepClassName}`}
            key={"step" + step}
          >
            {step + 1}
          </div>
        );
      }
      return (
        <Fragment key={"step" + step}>
          <div className={`${styles.step} ${stepClassName}`}>{step + 1}</div>
          <div className={`${styles.line} ${styles.incomingLine}`} />
        </Fragment>
      );
    })}
  </div>
);

export default Progression;
