import { FC, Fragment } from "react";

const Device: FC = () => {
  return (
    <Fragment>
      €
    </Fragment>
  );
};

export default Device;
