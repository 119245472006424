import { FC, Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ADMIN, DASHBOARD } from "../../../../../../App";
import Button from "../../../../../../Components/Common/Button";
import Notification from "../../../../../../Components/Common/Notification";
import { useUserContext } from "../../../../../../contexts/user/Context";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../../../translations/Context";
import { ITranslation } from "../../../../../../translations/types";
import { getBackendUrl } from "../../../../../../utils";
import getApi from "../../../../../../utils/axios";

export type IRemoveItemTranslations = {
  remove: ITranslation;
  removePopupDescription: ITranslation;
  removePopupTitle: ITranslation;
};

type Props = {
  isLoading: boolean;
  itemId: string;
  setIsLoading: (isLoading: boolean) => void;
};

const Remove: FC<Props> = ({ isLoading, itemId, setIsLoading }) => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  const translations = useTranslations("removeItem") as IRemoveItemTranslations;
  const { language } = useLanguageContext();
  const navigate = useNavigate();
  const { refresh } = useUserContext();

  return (
    <Fragment>
      {isNotificationVisible && (
        <Notification
          confirmLabel={translations.remove}
          onClose={() => setIsNotificationVisible(false)}
          onConfirm={() => {
            setIsLoading(true);
            getApi()
              .delete(`${getBackendUrl()}/item/${itemId}`)
              .then(() =>
                refresh().then(() =>
                  navigate(`/${language}/${ADMIN}/${DASHBOARD}`)
                )
              )
              .finally(() => setIsLoading(false));
          }}
          title={translations.removePopupTitle}
        >
          {translations.removePopupDescription}
        </Notification>
      )}
      <Button
        buttonType="danger"
        disabled={isLoading}
        onClick={() => setIsNotificationVisible(true)}
      >
        {translations.remove}
      </Button>
    </Fragment>
  );
};

export default Remove;
