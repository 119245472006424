import { FC, MouseEventHandler } from "react";

import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import styles from "./index.module.css";

type Props = {
  className?: string;
  onMouseEnter?: MouseEventHandler<HTMLImageElement>;
  onRemove?: () => void;
  src: string;
};

const Photo: FC<Props> = ({ className, onMouseEnter, onRemove, src }) => (
  <div
    className={styles.photo}
    key={src}
    onClick={(event) => {
      if (onRemove) {
        event.stopPropagation();
        onRemove();
      }
    }}
    onMouseEnter={onMouseEnter}
  >
    <img
      className={`${styles.img} ${className}`}
      src={src}
    />
    <DeleteOutlined className={styles.trash} />
  </div>
);

export default Photo;
