import { FC } from "react";

import { PaymentElement } from "@stripe/react-stripe-js";

import styles from "./index.module.css";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import { useTranslations } from "../../../../../translations/Context";
import { ITranslation } from "../../../../../translations/types";
import { IAddress, IUser } from "../../../../../types";
import Checkbox from "../Checkbox";

export type ICheckoutTranslations = {
  isSavingPaymentPreferences: ITranslation;
};

type Props = {
  billingAddress: IAddress;
  email: string;
  isSavingPaymentPreferences: boolean;
  message?: string | null;
  setIsLoading: (isLoading: boolean) => void;
  setIsSavingPaymentPreferences: (isSavingPaymentPreferences: boolean) => void;
  user?: IUser | null;
};

const Checkout: FC<Props> = ({
  billingAddress,
  email,
  isSavingPaymentPreferences,
  message,
  setIsLoading,
  setIsSavingPaymentPreferences,
  user,
}) => {
  const translations = useTranslations("checkoutForm") as ICheckoutTranslations;

  return (
    <div className={styles.wrapper}>
      <PaymentElement
        id="payment-element"
        onReady={() => setIsLoading(false)}
        options={{
          defaultValues: {
            billingDetails: {
              address: {
                city: billingAddress.city,
                country: billingAddress.country,
                line1: billingAddress.line1,
                line2: billingAddress.line2 || undefined,
                postal_code: billingAddress.postal_code,
                state: billingAddress.state,
              },
              email,
              name: `${billingAddress.firstName} ${billingAddress.lastName}`,
              phone: billingAddress.phone,
            },
          },
          layout: "accordion",
        }}
      />
      {user && (
        <Checkbox
          isChecked={isSavingPaymentPreferences}
          setIsChecked={setIsSavingPaymentPreferences}
          text={translations.isSavingPaymentPreferences}
        />
      )}
      <ErrorMessage message={message} />
    </div>
  );
};

export default Checkout;
