import { FC } from "react";

import styles from "./index.module.css";
import Remove from "./Remove";
import Update from "./Update";
import { IItem } from "../../../../../types";

type Props = {
  isLoading: boolean;
  item: IItem;
  setIsLoading: (isLoading: boolean) => void;
};

const Buttons: FC<Props> = ({ isLoading, item, setIsLoading }) => (
  <div className={styles.wrapper}>
    <Remove
      isLoading={isLoading}
      itemId={item._id}
      setIsLoading={setIsLoading}
    />
    <Update
      isLoading={isLoading}
      item={item}
      setIsLoading={setIsLoading}
    />
  </div>
);

export default Buttons;
