import { FC } from "react";

import Favorites from "./Favorites";
import Orders from "./Orders";
import Dashboard from "../../../Components/Dashboard";

const User: FC = () => (
  <Dashboard>
    <Orders />
    <Favorites />
  </Dashboard>
);

export default User;
