import { FC } from "react";

import uniq from "lodash/uniq";

import AddFirstPhoto from "./AddFirstPhoto";
import AddPhoto from "./AddPhoto";
import Photo from "./Photo";
import { IImage } from "../../../../../types";
import PhotoGallery from "../../../../Shop/ItemDetail/PhotoGallery";

type Props = {
  images: IImage[];
  setImages: (images: IImage[]) => void;
};

const Photos: FC<Props> = ({ images, setImages }) => {
  const addPhoto = (result: string) =>
    setImages(
      uniq([
        ...images,
        {
          url: result,
        },
      ])
    );

  return (
    <PhotoGallery
      getThumbnailElements={({
        getClassName,
        getOnMouseEnter,
        setCurrentImageIndex,
      }) => [
        ...images.map((image, index) => (
          <Photo
            className={getClassName(index)}
            key={image.url}
            onMouseEnter={getOnMouseEnter(index)}
            onRemove={() => {
              if (index === images.length - 1) {
                setCurrentImageIndex(index - 1);
              }
              setImages(
                images.filter((currentImage) => currentImage.url !== image.url)
              );
            }}
            src={image.url}
          />
        )),
        <AddPhoto
          addPhoto={addPhoto}
          key="addPhoto"
        />,
      ]}
      imageUrls={images.map((image) => image.url)}
      noImage={<AddFirstPhoto addPhoto={addPhoto} />}
    />
  );
};

export default Photos;
