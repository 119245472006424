import { FC, useEffect, useRef } from "react";

import styles from "./index.module.css";
import Button from "../../../../../Components/Common/Button";

type Props = {
  imageUrl: string;
  onClose: () => void;
  onIncrementImageIndex: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLImageElement>) => void;
};

const FullScreenImage: FC<Props> = ({
  imageUrl,
  onClose,
  onIncrementImageIndex,
  onKeyDown,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => imageRef.current?.focus(), []);

  return (
    <div
      className={styles.fullScreen}
      onClick={onClose}
    >
      <Button
        buttonType="text"
        className={styles.close}
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      >
        X
      </Button>
      <img
        onClick={(event) => {
          onIncrementImageIndex();
          event.stopPropagation();
        }}
        onKeyDown={onKeyDown}
        ref={imageRef}
        src={imageUrl}
        tabIndex={0}
      />
    </div>
  );
};

export default FullScreenImage;
