import { FC } from "react";

import styles from "./index.module.css";
import PaulEtGuillaume from "../../images/Paul&Guillaume.jpg";
import { useTranslations } from "../../translations/Context";
import { ITranslation } from "../../translations/types";

export type IAboutUsTranslations = {
  commonDescription: ITranslation;
  guillaumeDescription: ITranslation;
  hereIs: ITranslation;
  paulDecription: ITranslation;
  thisIs: ITranslation;
  whoAreWe: ITranslation;
};

const AboutUs: FC = () => {
  const translations = useTranslations("aboutUs") as IAboutUsTranslations;
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{translations.whoAreWe}</div>
      <div className={styles.introduction}>
        <div className={styles.guy}>
          <div>
            {translations.hereIs}
            <span className={styles.name}>Paul</span>,
          </div>
          <div>{translations.paulDecription}</div>
        </div>
        <img
          className={styles.photo}
          src={PaulEtGuillaume}
        />
        <div className={styles.guy}>
          <div>
            {translations.thisIs}
            <span className={styles.name}>Guillaume</span>,
          </div>
          <div>{translations.guillaumeDescription}</div>
        </div>
      </div>
      <div className={styles.us}>{translations.commonDescription}</div>
    </div>
  );
};

export default AboutUs;
