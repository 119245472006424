import { FC } from "react";

import { AddressElement } from "@stripe/react-stripe-js";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";

import styles from "./index.module.css";
import { useTranslations } from "../../../../../translations/Context";
import { ITranslation } from "../../../../../translations/types";
import { IAddress } from "../../../../../types";
import Checkbox from "../Checkbox";

export type IShippingAddressTranslations = {
  isSameBillingAddress: ITranslation;
};

export const getAddressFromStripe = (
  stripeAddress: StripeAddressElementChangeEvent["value"]
): IAddress => ({
  city: stripeAddress.address.city,
  country: stripeAddress.address.country,
  firstName: stripeAddress.firstName,
  lastName: stripeAddress.lastName,
  line1: stripeAddress.address.line1,
  line2: stripeAddress.address.line2,
  phone: stripeAddress.phone,
  postal_code: stripeAddress.address.postal_code,
  state: stripeAddress.address.state,
});

type Props = {
  isSameBillingAddress: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setIsSameBillingAddress: (isSameBillingAddress: boolean) => void;
  setShippingAddress: (shippingAddress: IAddress) => void;
  shippingAddress: IAddress;
};

const ShippingAddress: FC<Props> = ({
  isSameBillingAddress,
  setIsLoading,
  setIsSameBillingAddress,
  setShippingAddress,
  shippingAddress,
}) => {
  const translations = useTranslations(
    "shippingAddress"
  ) as IShippingAddressTranslations;
  return (
    <div className={styles.wrapper}>
      <AddressElement
        id="adress-element"
        onChange={(event) =>
          setShippingAddress(getAddressFromStripe(event.value))
        }
        onReady={() => setIsLoading(false)}
        options={{
          defaultValues: {
            address: {
              city: shippingAddress.city,
              country: shippingAddress.country,
              line1: shippingAddress.line1,
              line2: shippingAddress.line2,
              postal_code: shippingAddress.postal_code,
              state: shippingAddress.state,
            },
            firstName: shippingAddress.firstName,
            lastName: shippingAddress.lastName,
            phone: shippingAddress.phone,
          },
          display: {
            name: "split",
          },
          fields: {
            phone: "always",
          },
          mode: "shipping",
        }}
      />
      <Checkbox
        isChecked={isSameBillingAddress}
        setIsChecked={setIsSameBillingAddress}
        text={translations.isSameBillingAddress}
      />
    </div>
  );
};

export default ShippingAddress;
