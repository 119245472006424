import { FC, Fragment } from "react";

import "./style/var.css";
import axios from "axios";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  redirect,
  Route,
  RouterProvider,
} from "react-router-dom";

import Content from "./Components/Content";
import LoadingContextProvider from "./contexts/loading/Context";
import UserContextProvider from "./contexts/user/Context";
import AboutUs from "./Routes/AboutUs";
import Admin from "./Routes/Admin/Dashboard";
import CreateItem from "./Routes/Admin/Dashboard/CreateItem";
import UpdateItem from "./Routes/Admin/Dashboard/UpdateItem";
import CheckLoginPage from "./Routes/CheckLoginPage";
import Login from "./Routes/Connection/Login";
import Register from "./Routes/Connection/Register";
import DefaultErrorPage from "./Routes/ErrorPages/DefaultErrorPage";
import PageNotFound from "./Routes/ErrorPages/PageNotFound";
import Shop from "./Routes/Shop";
import Cart from "./Routes/Shop/Cart";
import ItemDetail from "./Routes/Shop/ItemDetail";
import ItemsList from "./Routes/Shop/ItemsList";
import Payment from "./Routes/Shop/Payment";
import PaymentSuccess from "./Routes/Shop/Payment/Success";
import OrdersContext from "./Routes/User";
import User from "./Routes/User/Dashboard";
import Order from "./Routes/User/Dashboard/Orders/Order";
import LanguageContextProvider from "./translations/Context";
import { LANGUAGES } from "./translations/types";
import { getBackendUrl } from "./utils";
import getApi from "./utils/axios";

export const ABOUT_US = "about-us";
export const ADMIN = "admin";
export const CART = "cart";
export const CREATE_ITEM = "create-item";
export const DASHBOARD = "dashboard";
export const ITEM = "item";
export const LOGIN = "login";
export const PAYMENT = "payment";
export const PAYMENT_SUCCESS = "payment-success";
export const REGISTER = "register";
export const SHOP = "shop";
export const USER = "user";
const ROOT = "/";

const pageNotFoundPaths = ["*", "/:language/*"];

const RoutesJSX = (
  <Fragment>
    <Route
      element={
        <Navigate
          replace
          to={`${LANGUAGES[0]}/${SHOP}`}
        />
      }
      errorElement={<DefaultErrorPage />}
      path={ROOT}
    />
    <Route
      element={
        <LanguageContextProvider>
          <LoadingContextProvider>
            <UserContextProvider>
              <Content />
            </UserContextProvider>
          </LoadingContextProvider>
        </LanguageContextProvider>
      }
      errorElement={<DefaultErrorPage />}
      path={":language/"}
    >
      <Route
        element={
          <Navigate
            replace
            to={SHOP}
          />
        }
        index
      />
      <Route
        element={<AboutUs />}
        errorElement={<DefaultErrorPage />}
        path={ABOUT_US}
      />
      <Route
        element={<Shop />}
        errorElement={<DefaultErrorPage />}
        path={SHOP}
      >
        <Route
          element={<ItemsList />}
          errorElement={<DefaultErrorPage />}
          index
          loader={async () => await axios.get(`${getBackendUrl()}/item`)}
        />
        <Route
          element={<ItemDetail />}
          errorElement={<DefaultErrorPage />}
          loader={async ({ params }) =>
            await axios.get(`${getBackendUrl()}/item/${params.itemId}`)
          }
          path={`${ITEM}/:itemId`}
        />
        <Route
          element={<Cart />}
          errorElement={<DefaultErrorPage />}
          path={`${CART}`}
        />
        <Route
          element={<Login isAdmin={false} />}
          errorElement={<DefaultErrorPage />}
          path={LOGIN}
        />
        <Route
          element={<Register isAdmin={false} />}
          errorElement={<DefaultErrorPage />}
          path={REGISTER}
        />

        <Route
          element={<Payment />}
          errorElement={<DefaultErrorPage />}
          path={PAYMENT}
        />
        <Route
          element={<PaymentSuccess />}
          errorElement={<DefaultErrorPage />}
          loader={async ({ request }) => {
            const paymentId = new URLSearchParams(
              request.url.split("?")[1]
            ).get("payment_intent");
            return await getApi().get(
              `${getBackendUrl()}/payment/order/${paymentId}`
            );
          }}
          path={PAYMENT_SUCCESS}
        />
        <Route
          element={<CheckLoginPage isAdmin={false} />}
          errorElement={<DefaultErrorPage />}
          path={USER}
        >
          <Route
            element={
              <Navigate
                replace
                to={DASHBOARD}
              />
            }
            index
          />
          <Route
            element={<OrdersContext />}
            loader={async () =>
              await getApi()
                .get(`${getBackendUrl()}/user/orders`)
                .catch((error) => error.status === 401 && redirect(".."))
            }
            path={DASHBOARD}
          >
            <Route
              element={<User />}
              index
            />
            <Route
              element={<Order />}
              path=":orderId/"
            />
          </Route>
        </Route>
      </Route>
      <Route
        errorElement={<DefaultErrorPage />}
        path={ADMIN}
      >
        <Route
          element={
            <Navigate
              replace
              to={DASHBOARD}
            />
          }
          index
        />
        <Route
          element={<Login isAdmin={true} />}
          errorElement={<DefaultErrorPage />}
          path={LOGIN}
        />
        <Route
          element={<Register isAdmin={true} />}
          errorElement={<DefaultErrorPage />}
          path={REGISTER}
        />
        <Route
          element={<CheckLoginPage isAdmin={true} />}
          path={DASHBOARD}
        >
          <Route
            element={<Admin />}
            index
          />
          <Route
            element={<CreateItem />}
            path={`${ITEM}/${CREATE_ITEM}`}
          />
          <Route
            element={<UpdateItem />}
            loader={async ({ params }) =>
              await axios.get(`${getBackendUrl()}/item/${params.itemId}`)
            }
            path={`${ITEM}/:itemId`}
          />
        </Route>
      </Route>
      {pageNotFoundPaths.map((path) => (
        <Route
          element={<PageNotFound />}
          key={path}
          path={path}
        />
      ))}
    </Route>
  </Fragment>
);

const router = createBrowserRouter(createRoutesFromElements(RoutesJSX));

const App: FC = () => <RouterProvider router={router} />;

export default App;
