import { FC, Fragment, useRef } from "react";

import styles from "./index.module.css";
import Button from "../../../../../../Components/Common/Button";

const handleFileChange = ({
  event,
  onLoadEnd,
}: {
  event: React.ChangeEvent<HTMLInputElement>;
  onLoadEnd: (result: string) => void;
}) => {
  if (!event.target.files || !event.target.files.length) {
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onloadend = () => onLoadEnd(reader.result as string);
};

type Props = {
  addPhoto: (result: string) => void;
  className?: string;
  innerRef?: React.LegacyRef<HTMLButtonElement>;
};

const AddPhoto: FC<Props> = ({ addPhoto, className = "", innerRef }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <Fragment>
      <input
        className={styles.input}
        onChange={(event) =>
          handleFileChange({
            event,
            onLoadEnd: addPhoto,
          })
        }
        onClick={(event) => event.stopPropagation()}
        ref={fileInputRef}
        type="file"
      />
      <Button
        buttonType="white"
        className={`${styles.addPhoto} ${className}`}
        innerRef={innerRef}
        onClick={(event) => {
          event.stopPropagation();
          fileInputRef.current?.click();
        }}
        type="button"
      >
        &#xFF0B;
      </Button>
    </Fragment>
  );
};

export default AddPhoto;
