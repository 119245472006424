import { ButtonHTMLAttributes, FC, ReactNode } from "react";

import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import omit from "lodash/omit";

import styles from "./index.module.css";

export type IButtonType = "black" | "danger" | "text" | "white";

const getClassName = ({
  buttonType,
  className,
}: {
  buttonType: IButtonType;
  className: string;
}): string => {
  switch (buttonType) {
    case "black":
      return `${styles.common} ${styles.black} ${className}`;
    case "danger":
      return `${styles.common} ${styles.danger} ${className}`;
    case "text":
      return `${styles.common} ${styles.text} ${className}`;
    case "white":
      return `${styles.common} ${styles.white} ${className}`;
  }
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: IButtonType;
  children: ReactNode;
  innerRef?: React.LegacyRef<HTMLButtonElement>;
  isLoading?: boolean;
}

const Button: FC<Props> = (props) => {
  const { buttonType = "black", children, className = "" } = props;
  return (
    <button
      {...omit(props, ["buttonType", "innerRef", "isLoading"])}
      className={getClassName({ buttonType, className })}
      ref={props.innerRef}
    >
      {props.isLoading && <LoadingOutlined />}
      {children}
    </button>
  );
};

export default Button;
