import { FC } from "react";

import { AddressElement } from "@stripe/react-stripe-js";

import { IAddress } from "../../../../../types";
import { getAddressFromStripe } from "../ShippingAddress";

type Props = {
  billingAddress: IAddress;
  setBillingAddress: (billingAddress: IAddress) => void;
  setIsLoading: (isLoading: boolean) => void;
};

const BillingAddress: FC<Props> = ({
  billingAddress,
  setBillingAddress,
  setIsLoading,
}) => (
  <AddressElement
    id="adress-element"
    onChange={(event) => setBillingAddress(getAddressFromStripe(event.value))}
    onReady={() => setIsLoading(false)}
    options={{
      defaultValues: {
        address: {
          city: billingAddress.city,
          country: billingAddress.country,
          line1: billingAddress.line1,
          line2: billingAddress.line2,
          postal_code: billingAddress.postal_code,
          state: billingAddress.state,
        },
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        phone: billingAddress.phone,
      },
      display: {
        name: "split",
      },
      fields: {
        phone: "always",
      },
      mode: "billing",
    }}
  />
);

export default BillingAddress;
