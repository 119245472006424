import { FC } from "react";

import { Link } from "react-router-dom";

import styles from "./index.module.css";
import Item from "./Item";
import EditableQuantities from "./Item/EditableQuantities";
import { ITEM } from "../../../../App";
import Price from "../../../../Components/Price";
import SizePicker from "../../../../Components/SizePicker";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../translations/types";
import { ICartItem } from "../../../../types";
import {
  editQuantity,
  editSize,
  removeItemFromCart,
  setNewCart,
} from "../utils";

export type IItemsTranslations = {
  name: ITranslation;
  linePrice: ITranslation;
  size: ITranslation;
};

type Props = {
  items: ICartItem[];
};

const Items: FC<Props> = ({ items }) => {
  const { language } = useLanguageContext();
  const translations = useTranslations("items") as IItemsTranslations &
    ICommonTranslations;
  return (
    <div>
      <Item
        className={styles.header}
        linePrice={translations.linePrice}
        name={translations.name}
        quantity={translations.quantity}
        size={translations.size}
        unitPrice={translations.unitPrice}
      />
      {items.map((item) => (
        <div key={item._id + item.size}>
          <Item
            linePrice={<Price price={item.price * item.quantity} />}
            name={
              <Link
                className={styles.name}
                to={`/${language}/${ITEM}/${item._id}`}
              >
                {item.name}
              </Link>
            }
            onRemove={() => setNewCart(removeItemFromCart(item))}
            quantity={
              <EditableQuantities
                onQuantityEdition={(quantity) =>
                  setNewCart(editQuantity({ item, quantity }))
                }
                onRemove={() => setNewCart(removeItemFromCart(item))}
                quantity={item.quantity}
              />
            }
            size={
              <SizePicker
                availableSizes={item.availableSizes}
                size={item.size}
                setSize={(size) => setNewCart(editSize({ item, size }))}
              />
            }
            unitPrice={<Price price={item.price} />}
          />
        </div>
      ))}
    </div>
  );
};

export default Items;
