import { FC, ReactNode } from "react";

import styles from "./index.module.css";

type Props = {
  children: ReactNode;
};

const Dashboard: FC<Props> = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export default Dashboard;
