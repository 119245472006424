import { FC, Fragment, useState } from "react";

import ShoppingCartOutlined from "@ant-design/icons/ShoppingCartOutlined";
import { useNavigate } from "react-router-dom";

import { CART, SHOP } from "../../../../../App";
import Button from "../../../../../Components/Common/Button";
import Notification from "../../../../../Components/Common/Notification";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../../translations/Context";
import { ITranslation } from "../../../../../translations/types";
import { ICartItem } from "../../../../../types";
import { addItemToCart, setNewCart } from "../../../Cart/utils";

export type IAddToCartTranslations = {
  addToCart: ITranslation;
  continueShopping: ITranslation;
  goToCart: ITranslation;
  itemAddedToCart: ITranslation;
};

const AddToCart: FC<ICartItem> = (item) => {
  const translations = useTranslations("addToCart") as IAddToCartTranslations;
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  const { language } = useLanguageContext();

  const navigate = useNavigate();
  return (
    <Fragment>
      {isNotificationVisible && (
        <Notification
          cancelLabel={translations.continueShopping}
          confirmButtonType="black"
          confirmLabel={translations.goToCart}
          onClose={() => setIsNotificationVisible(false)}
          onConfirm={() => navigate(`/${language}/${SHOP}/${CART}`)}
        >
          {translations.itemAddedToCart}
        </Notification>
      )}
      <Button
        onClick={() => {
          setNewCart(
            addItemToCart({
              ...item,
            })
          );
          setIsNotificationVisible(true);
        }}
      >
        <div>{translations.addToCart}</div>
        <ShoppingCartOutlined />
      </Button>
    </Fragment>
  );
};

export default AddToCart;
