import { FC } from "react";

import { ADMIN, DASHBOARD, LOGIN } from "../../../App";
import User from "../User";

const Admin: FC = () => (
  <User
    dashboardUrl={`${ADMIN}/${DASHBOARD}/`}
    loginUrl={`${ADMIN}/${LOGIN}`}
  />
);

export default Admin;
