import { ReactNode } from "react";

import { IBackToPageButtonTranslations } from "../Components/BackToPageButton";
import { INotificationTranslations } from "../Components/Common/Notification";
import { IFooterTranslations } from "../Components/Footer";
import { IUserTranslations } from "../Components/Header/User";
import { IAboutUsTranslations } from "../Routes/AboutUs";
import { ICreateItemTranslations } from "../Routes/Admin/Dashboard/CreateItem";
import { IItemDetailsTranslation } from "../Routes/Admin/Dashboard/ItemDetails";
import { IAddFirstPhotoTranslations } from "../Routes/Admin/Dashboard/ItemDetails/Photos/AddFirstPhoto";
import { IAddNewItemTranslations } from "../Routes/Admin/Dashboard/Items";
import { IRemoveItemTranslations } from "../Routes/Admin/Dashboard/UpdateItem/Buttons/Remove";
import { IUpdateButtonTranslations } from "../Routes/Admin/Dashboard/UpdateItem/Buttons/Update";
import { ILoginTranslations } from "../Routes/Connection/Login";
import { ILoginFooterTranslations } from "../Routes/Connection/Login/Footer";
import { IRegisterTranslations } from "../Routes/Connection/Register";
import { IRegisterFooterTranslations } from "../Routes/Connection/Register/Footer";
import { IDefaultErrorPageTranslations } from "../Routes/ErrorPages/DefaultErrorPage";
import { INotAdminPage } from "../Routes/ErrorPages/NotAdminPage";
import { IPageNotFoundTranslations } from "../Routes/ErrorPages/PageNotFound";
import { ICartTranslations } from "../Routes/Shop/Cart";
import { IActionsTranslations } from "../Routes/Shop/Cart/Actions";
import { IEmptyCartTranslations } from "../Routes/Shop/Cart/EmptyCart";
import { IItemsTranslations } from "../Routes/Shop/Cart/Items";
import { IRemoveLineNotificationTranslations } from "../Routes/Shop/Cart/Items/Item/RemoveLineNotification";
import { ITotalPriceTranslations } from "../Routes/Shop/Cart/TotalPrice";
import { IAddToCartTranslations } from "../Routes/Shop/ItemsList/Item/AddToCart";
import { IPaymentStepTranslations } from "../Routes/Shop/Payment/Steps";
import { ICheckoutTranslations } from "../Routes/Shop/Payment/Steps/Checkout";
import { IShippingAddressTranslations } from "../Routes/Shop/Payment/Steps/ShippingAddress";
import { IPaymentSuccessTranslations } from "../Routes/Shop/Payment/Success";
import { IFavoritesTranslations } from "../Routes/User/Dashboard/Favorites";
import { IOrdersTranslations } from "../Routes/User/Dashboard/Orders";
import { IOrderTranslations } from "../Routes/User/Dashboard/Orders/Order";

export const ENGLISH = "en";
export const FRENCH = "fr";
export const LANGUAGES = [FRENCH, ENGLISH];
export type ILanguage = (typeof LANGUAGES)[number];

export type ITranslation = string | ReactNode;

export type ICommonTranslations = {
  availableSizes: ITranslation;
  backToDashboard: ITranslation;
  columns: ITranslation;
  date: ITranslation;
  password: ITranslation;
  price: ITranslation;
  quantity: ITranslation;
  unitPrice: ITranslation;
  unknownError: (errorDescription?: string) => ITranslation;
  username: ITranslation;
};

export type AppTranslations = {
  aboutUs: IAboutUsTranslations;
  actions: IActionsTranslations;
  addFirstPhoto: IAddFirstPhotoTranslations;
  addNewItem: IAddNewItemTranslations;
  addToCart: IAddToCartTranslations;
  backToPage: IBackToPageButtonTranslations;
  cart: ICartTranslations;
  checkoutForm: ICheckoutTranslations;
  common: ICommonTranslations;
  createItem: ICreateItemTranslations;
  defaultErrorPage: IDefaultErrorPageTranslations;
  favorites: IFavoritesTranslations;
  emptyCart: IEmptyCartTranslations;
  footer: IFooterTranslations;
  itemDetails: IItemDetailsTranslation;
  items: IItemsTranslations;
  login: ILoginTranslations;
  loginFooter: ILoginFooterTranslations;
  notAdminPage: INotAdminPage;
  notification: INotificationTranslations;
  order: IOrderTranslations;
  orders: IOrdersTranslations;
  pageNotFound: IPageNotFoundTranslations;
  paymentStepTranslations: IPaymentStepTranslations;
  paymentSuccess: IPaymentSuccessTranslations;
  register: IRegisterTranslations;
  registerFooter: IRegisterFooterTranslations;
  removeItem: IRemoveItemTranslations;
  removeLineNotification: IRemoveLineNotificationTranslations;
  shippingAddress: IShippingAddressTranslations;
  totalPrice: ITotalPriceTranslations;
  updateButton: IUpdateButtonTranslations;
  user: IUserTranslations;
};
