import { enTranslations } from "./en";
import { frenchTranslations } from "./fr";
import { AppTranslations, ILanguage } from "./types";

const appTranslations: { [language in ILanguage]: AppTranslations } = {
  en: enTranslations,
  fr: frenchTranslations,
};

export const getTranslations = ({
  language = "fr",
  module = "common",
}: {
  language?: ILanguage;
  module?: keyof AppTranslations;
}) => ({
  ...appTranslations[language]["common"],
  ...appTranslations[language][module],
});
