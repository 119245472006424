import { FC } from "react";

import styles from "./index.module.css";
import ToItemsLink from "../../../../Components/ToItemsLink";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";

export type IEmptyCartTranslations = {
  emptyCart: ITranslation;
  fillIt: ITranslation;
};

const EmptyCart: FC = () => {
  const translations = useTranslations("emptyCart") as IEmptyCartTranslations;
  return (
    <div className={styles.wrapper}>
      <div>{translations.emptyCart}</div>
      <ToItemsLink
        buttonType="black"
        text={translations.fillIt}
      />
    </div>
  );
};

export default EmptyCart;
