import { FC, useState } from "react";

import axios from "axios";

import Footer from "./Footer";
import Connection from "..";
import { ADMIN, REGISTER, SHOP } from "../../../App";
import { useTranslations } from "../../../translations/Context";
import { ICommonTranslations, ITranslation } from "../../../translations/types";
import { getBackendUrl } from "../../../utils";
import Entry from "../Entry";

export type ILoginTranslations = {
  title: ITranslation;
  unknownUser: ITranslation;
  wrongPairUserPassword: ITranslation;
};

const getFooterUrl = (isAdmin: boolean) => {
  if (isAdmin) {
    return `${ADMIN}/${REGISTER}`;
  } else {
    return `${SHOP}/${REGISTER}`;
  }
};

type Props = {
  isAdmin: boolean;
};

const getErrorMessage =
  (translations: ILoginTranslations) => (status: number) => {
    switch (status) {
      case 0:
        return translations.unknownUser;
      case 1:
        return translations.wrongPairUserPassword;
      default:
        return "Unexpected";
    }
  };

const Login: FC<Props> = ({ isAdmin }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const translations = useTranslations("login") as ILoginTranslations &
    ICommonTranslations;

  return (
    <Connection
      footer={<Footer pathToRegister={getFooterUrl(isAdmin)} />}
      getErrorMessage={getErrorMessage(translations)}
      isButtonDisabled={!username || !password}
      onSubmit={() =>
        axios.post(`${getBackendUrl()}/user/login`, {
          password,
          username,
        })
      }
      title={translations.title}
      urlSuffix={isAdmin ? ADMIN : ""}
    >
      <Entry
        inputProps={{
          autoComplete: "username",
          onChange: (event) => setUsername(event.target.value),
          value: username,
        }}
        label={translations.username}
      />
      <Entry
        inputProps={{
          autoComplete: "current-password",
          onChange: (event) => setPassword(event.target.value),
          type: "password",
          value: password,
        }}
        label={translations.password}
      />
    </Connection>
  );
};

export default Login;
