import { FC, Fragment } from "react";

import Link from "../../../../Components/Common/Link";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";

export type IRegisterFooterTranslations = {
  alreadyRegistered: ITranslation;
  loginHere: ITranslation;
};

type Props = {
  onLoginClick?: () => void;
  pathToLogin: string;
};

const Footer: FC<Props> = ({ onLoginClick, pathToLogin }) => {
  const translations = useTranslations(
    "registerFooter"
  ) as IRegisterFooterTranslations;
  return (
    <Fragment>
      <div>{translations.alreadyRegistered}</div>
      <Link
        buttonType="text"
        onClick={onLoginClick}
        route={pathToLogin}
      >
        {translations.loginHere}
      </Link>
    </Fragment>
  );
};

export default Footer;
