import { FC, ReactNode } from "react";

import styles from "./index.module.css";

export type IGetElements = {
  getClassName: (index: number) => string;
  getOnMouseEnter: (
    index: number
  ) => React.MouseEventHandler<HTMLImageElement> | undefined;
  setCurrentImageIndex: (index: number) => void;
};

type Props = {
  currentImageIndex: number;
  getElements: ({
    getClassName,
    getOnMouseEnter,
    setCurrentImageIndex,
  }: IGetElements) => ReactNode[];
  setCurrentImageIndex: (index: number) => void;
};

const Thumbnails: FC<Props> = ({
  currentImageIndex,
  getElements,
  setCurrentImageIndex,
}) => {
  const elements = getElements({
    getClassName: (index) =>
      index === currentImageIndex
        ? `${styles.thumbnail} ${styles.currentThumbnail}`
        : styles.thumbnail,
    getOnMouseEnter: (index) => (event) => {
      setCurrentImageIndex(index);
      event.stopPropagation();
    },
    setCurrentImageIndex,
  });

  return (
    elements.length > 1 && <div className={styles.wrapper}>{elements}</div>
  );
};

export default Thumbnails;
