import { FC } from "react";

import StopOutlined from "@ant-design/icons/StopOutlined";

import styles from "./index.module.css";
import BackToPageButton from "../../../Components/BackToPageButton";
import { useTranslations } from "../../../translations/Context";
import { ITranslation } from "../../../translations/types";

export type INotAdminPage = {
  backHome: ITranslation;
  description: ITranslation;
};

const NotAdminPage: FC = () => {
  const translations = useTranslations("notAdminPage") as INotAdminPage;

  return (
    <div className={styles.wrapper}>
      <StopOutlined className={styles.icon} />
      <div className={styles.description}>{translations.description}</div>
      <BackToPageButton
        buttonType="white"
        label={translations.backHome}
        route=""
      />
    </div>
  );
};

export default NotAdminPage;
