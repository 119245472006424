import { FC, useState } from "react";

import styles from "./index.module.css";
import Progression from "./Progression";
import Step from "./Step";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";
import { IUser } from "../../../../types";

export const BILLING_ADDRESS = "BILLING_ADDRESS";
const CREDIT_CARD = "CREDIT_CARD";
const EMAIL = "EMAIL";
export const SHIPPING_ADDRESS = "SHIPPING_ADDRESS";

export type IPaymentStepTranslations = {
  [BILLING_ADDRESS]: ITranslation;
  [CREDIT_CARD]: ITranslation;
  [EMAIL]: ITranslation;
  missingEmail: string;
  missingFirstNameOrLastName: string;
  payNow: ITranslation;
  [SHIPPING_ADDRESS]: ITranslation;
};

export type IStep =
  | typeof BILLING_ADDRESS
  | typeof CREDIT_CARD
  | typeof EMAIL
  | typeof SHIPPING_ADDRESS;

export const ORDERED_STEP: IStep[] = [
  EMAIL,
  SHIPPING_ADDRESS,
  BILLING_ADDRESS,
  CREDIT_CARD,
];

type Props = {
  orderId: string;
  user?: IUser | null;
};

const Steps: FC<Props> = ({ orderId, user }) => {
  const [step, setStep] = useState<IStep>(ORDERED_STEP[0]);

  const translations = useTranslations(
    "paymentStepTranslations"
  ) as IPaymentStepTranslations;

  return (
    <div className={styles.wrapper}>
      <Progression
        currentStep={ORDERED_STEP.indexOf(step)}
        numberOfSteps={ORDERED_STEP.length}
        onStepClick={(index) => setStep(ORDERED_STEP[index])}
      />
      <div className={styles.title}>{translations[step]}</div>
      <Step
        orderId={orderId}
        setStep={setStep}
        step={step}
        user={user}
      />
    </div>
  );
};

export default Steps;
