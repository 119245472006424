import { FC, ReactNode } from "react";

import styles from "./index.module.css";
import { useTranslations } from "../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../translations/types";

type Props = {
  children: ITranslation;
  name: ReactNode;
};

const Field: FC<Props> = ({ children, name }) => {
  const translations = useTranslations() as ICommonTranslations;
  return (
    <div className={styles.wrapper}>
      <div className={styles.fieldName}>
        {name}
        {translations.columns}
      </div>
      {children}
    </div>
  );
};

export default Field;
