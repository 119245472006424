export const getBackendUrl = (): string =>
  process.env.REACT_APP_BACKEND_URL || "";

export const roundTwoDigits = (toRound: number) => toRound.toFixed(2);

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year}`;
};
