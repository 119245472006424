import { FC, Fragment } from "react";

import { DASHBOARD, LOGIN, SHOP, USER } from "../../../App";
import Cart from "../Cart";
import User from "../User";

const Shop: FC = () => (
  <Fragment>
    <Cart />
    <User
      dashboardUrl={`${SHOP}/${USER}/${DASHBOARD}`}
      loginUrl={`${SHOP}/${LOGIN}`}
    />
  </Fragment>
);

export default Shop;
