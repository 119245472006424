import { FC } from "react";

import RollbackOutlined from "@ant-design/icons/RollbackOutlined";
import { useNavigate, useParams } from "react-router-dom";

import { getTranslations } from "../../translations";
import { ILanguage, ITranslation } from "../../translations/types";
import Button, { IButtonType } from "../Common/Button";

export type IBackToPageButtonTranslations = {
  backToPreviousPage: ITranslation;
};

type Props = {
  buttonType?: IButtonType;
  label?: ITranslation;
  route?: string;
};

const BackToPageButton: FC<Props> = ({
  buttonType = "black",
  label,
  route,
}) => {
  const { language } = useParams();

  const translations = getTranslations({
    language: language as ILanguage,
    module: "backToPage",
  }) as IBackToPageButtonTranslations;

  const navigate = useNavigate();

  return (
    <Button
      buttonType={buttonType}
      onClick={() => (route ? navigate(`/${language}/${route}`) : navigate(-1))}
    >
      <RollbackOutlined />
      {label || translations.backToPreviousPage}
    </Button>
  );
};

export default BackToPageButton;
