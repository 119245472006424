import { FC, Fragment } from "react";

import { Link, useLocation } from "react-router-dom";

import styles from "./index.module.css";
import { useLanguageContext } from "../../../translations/Context";
import { LANGUAGES } from "../../../translations/types";

const Language: FC = () => {
  const { language: currentLanguage } = useLanguageContext();
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {LANGUAGES.map((language) => (
        <Link
          className={
            language === currentLanguage ? `link ${styles.selected}` : "link"
          }
          key={language}
          onClick={(event) => {
            if (language === currentLanguage) {
              event.preventDefault();
            }
          }}
          to={location.pathname
            .split("/")
            .slice(2)
            .reduce((previous, next) => `${previous}/${next}`, `/${language}`)}
        >
          {language}
        </Link>
      )).reduce((previous, next) => (
        <Fragment>
          {previous}/{next}
        </Fragment>
      ))}
    </div>
  );
};

export default Language;
