import { FC } from "react";

import ArrowRightOutlined from "@ant-design/icons/ArrowRightOutlined";
import { AxiosResponse } from "axios";

import CheckoutButton from "./Checkout";
import {
  BILLING_ADDRESS,
  IPaymentStepTranslations,
  IStep,
  ORDERED_STEP,
  SHIPPING_ADDRESS,
} from "..";
import Button from "../../../../../Components/Common/Button";
import { useTranslations } from "../../../../../translations/Context";
import { IAddress } from "../../../../../types";

const getNextStep = ({
  isSameBillingAddress,
  step,
}: {
  isSameBillingAddress: boolean;
  step: IStep;
}) => {
  if (
    ORDERED_STEP[ORDERED_STEP.indexOf(step) + 1] === BILLING_ADDRESS &&
    isSameBillingAddress
  ) {
    return ORDERED_STEP[ORDERED_STEP.indexOf(step) + 2];
  }
  return ORDERED_STEP[ORDERED_STEP.indexOf(step) + 1];
};

const isNextDisabled = ({
  billingAddress,
  email,
  isLoading,
  setMessage,
  shippingAddress,
  step,
  translations,
}: {
  billingAddress: IAddress;
  email: string;
  isLoading: boolean;
  setMessage: (message: string | null) => void;
  shippingAddress: IAddress;
  step: IStep;
  translations: IPaymentStepTranslations;
}): boolean => {
  if (isLoading) {
    return true;
  }
  if (!email) {
    setMessage(translations.missingEmail);
    return true;
  }
  if (
    step === SHIPPING_ADDRESS &&
    (!shippingAddress.firstName || !shippingAddress.lastName)
  ) {
    setMessage(translations.missingFirstNameOrLastName);
    return true;
  }
  if (
    step === BILLING_ADDRESS &&
    (!billingAddress.firstName || !billingAddress.lastName)
  ) {
    setMessage(translations.missingFirstNameOrLastName);
    return true;
  }
  setMessage("");
  return false;
};

type Props = {
  billingAddress: IAddress;
  email: string;
  isLoading: boolean;
  isSameBillingAddress: boolean;
  onPayment: () => Promise<AxiosResponse<string>>;
  setIsLoading: (isLoading: boolean) => void;
  setMessage: (message: string | null) => void;
  setStep: (step: IStep) => void;
  shippingAddress: IAddress;
  step: IStep;
};

const NextButton: FC<Props> = ({
  billingAddress,
  email,
  isLoading,
  isSameBillingAddress,
  onPayment,
  setIsLoading,
  setMessage,
  setStep,
  shippingAddress,
  step,
}) => {
  const translations = useTranslations(
    "paymentStepTranslations"
  ) as IPaymentStepTranslations;

  if (ORDERED_STEP.indexOf(step) === ORDERED_STEP.length - 1) {
    return (
      <CheckoutButton
        email={email}
        isLoading={isLoading}
        onPayment={onPayment}
        setIsLoading={setIsLoading}
        setMessage={setMessage}
        shippingAddress={shippingAddress}
      />
    );
  }

  return (
    <Button
      buttonType="black"
      disabled={isNextDisabled({
        billingAddress,
        email,
        isLoading,
        setMessage,
        shippingAddress,
        step,
        translations,
      })}
      isLoading={isLoading}
      onClick={() => {
        setIsLoading(true);
        setStep(getNextStep({ isSameBillingAddress, step }));
      }}
    >
      {translations[getNextStep({ isSameBillingAddress, step })]}
      <ArrowRightOutlined />
    </Button>
  );
};

export default NextButton;
