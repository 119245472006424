import { FC, useState } from "react";

import RightOutlined from "@ant-design/icons/RightOutlined";

import styles from "./index.module.css";
import LeftIcon from "./LeftIcon";
import Button from "../../../../../../Components/Common/Button";
import RemoveLineNotification from "../RemoveLineNotification";

type Props = {
  className?: string;
  onQuantityEdition: (quantity: number) => void;
  onRemove?: () => void;
  quantity: number;
};

const EditableQuantities: FC<Props> = ({
  className = "",
  onQuantityEdition,
  onRemove,
  quantity,
}) => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {isNotificationVisible && (
        <RemoveLineNotification
          onClose={() => setIsNotificationVisible(false)}
          onRemove={onRemove}
        />
      )}
      <LeftIcon
        onQuantityEdition={onQuantityEdition}
        onRemove={onRemove}
        quantity={quantity}
        setIsNotificationVisible={setIsNotificationVisible}
      />
      <input
        className={styles.quantity}
        min={1}
        onChange={(event) => {
          const newQuantity = Number.parseInt(event.target.value);
          if (newQuantity < 1) {
            return onQuantityEdition(1);
          }
          onQuantityEdition(newQuantity);
        }}
        type="number"
        value={quantity}
      />
      <Button
        buttonType="text"
        className={styles.icon}
        onClick={() => onQuantityEdition(quantity + 1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );
};

export default EditableQuantities;
