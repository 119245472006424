import { FC } from "react";

import styles from "./index.module.css";
import Notification from "../../../../../../Components/Common/Notification";
import { useTranslations } from "../../../../../../translations/Context";
import { ITranslation } from "../../../../../../translations/types";

export type IRemoveLineNotificationTranslations = {
  content: ITranslation;
};

type Props = {
  onClose: () => void;
  onRemove?: () => void;
};

const RemoveLineNotification: FC<Props> = ({ onClose, onRemove }) => {
  const translations = useTranslations(
    "removeLineNotification"
  ) as IRemoveLineNotificationTranslations;
  return (
    <Notification
      className={styles.content}
      onClose={onClose}
      onConfirm={onRemove}
    >
      {translations.content}
    </Notification>
  );
};

export default RemoveLineNotification;
