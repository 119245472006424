import { FC, Fragment } from "react";

import { IItemDetailsTranslation } from "..";
import { useTranslations } from "../../../../../translations/Context";
import { ICommonTranslations } from "../../../../../translations/types";
import Label from "../Label";

type Props = {
  name: string;
  setName: (name: string) => void;
};

const Name: FC<Props> = ({ name, setName }) => {
  const translations = useTranslations(
    "itemDetails"
  ) as IItemDetailsTranslation & ICommonTranslations;

  return (
    <Fragment>
      <Label label={translations.name} />
      <input
        onChange={(event) => setName(event.target.value)}
        value={name}
      />
    </Fragment>
  );
};

export default Name;
