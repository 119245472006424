import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from "react";

import FullScreenImage from "./FullScreenImage";
import Photos from "./Photos";
import { IGetElements } from "./Thumbnails";

export const onKeyDownProvider =
  ({
    imagesNumber,
    onClose,
    setCurrentImageIndex,
  }: {
    imagesNumber: number;
    onClose: () => void;
    setCurrentImageIndex: (index: number) => void;
  }) =>
  ({
    currentImageIndex,
    event,
  }: {
    currentImageIndex: number;
    event: React.KeyboardEvent<HTMLImageElement>;
  }) => {
    event.preventDefault();
    switch (event.key) {
      case "Escape":
        onClose();
        return;
      case "ArrowRight":
        setCurrentImageIndex((currentImageIndex + 1) % imagesNumber);
        return;
      case "ArrowLeft":
        setCurrentImageIndex(
          currentImageIndex === 0 ? imagesNumber - 1 : currentImageIndex - 1
        );
        return;
      default:
        return;
    }
  };

type Props = {
  getThumbnailElements: ({
    getClassName,
    getOnMouseEnter,
    setCurrentImageIndex,
  }: IGetElements) => ReactNode[];
  imageUrls: string[];
  noImage?: ReactNode;
};

const PhotoGallery: FC<Props> = ({
  getThumbnailElements,
  imageUrls,
  noImage,
}) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const onClose = useCallback(() => setIsFullScreen(false), []);

  const onKeyDown = useMemo(
    () =>
      onKeyDownProvider({
        imagesNumber: imageUrls.length,
        onClose,
        setCurrentImageIndex,
      }),
    [imageUrls, onClose, setCurrentImageIndex]
  );

  return (
    <Fragment>
      {isFullScreen && (
        <FullScreenImage
          imageUrl={imageUrls[currentImageIndex]}
          onClose={onClose}
          onIncrementImageIndex={() =>
            setCurrentImageIndex((currentImageIndex + 1) % imageUrls.length)
          }
          onKeyDown={(event) => onKeyDown({ currentImageIndex, event })}
        />
      )}
      <Photos
        currentImageIndex={currentImageIndex}
        getThumbnailElements={getThumbnailElements}
        imageUrls={imageUrls}
        isFullScreen={isFullScreen}
        noImage={noImage}
        onKeyDown={onKeyDown}
        setCurrentImageIndex={setCurrentImageIndex}
        setIsFullScreen={setIsFullScreen}
      />
    </Fragment>
  );
};

export default PhotoGallery;
