import { FC } from "react";

import AddItem from "./AddItem";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";
import { IItem } from "../../../../types";
import ItemDetails from "../ItemDetails";

export type ICreateItemTranslations = {
  addAnother: ITranslation;
  addItem: ITranslation;
  itemCreatedSuccessfully: ITranslation;
  title: ITranslation;
};

const DEFAULT_ITEM: IItem = {
  _id: "",
  availableSizes: [],
  images: [],
  name: "",
  price: 1,
};

const CreateItem: FC = () => {
  const translations = useTranslations("createItem") as ICreateItemTranslations;
  return (
    <ItemDetails
      item={DEFAULT_ITEM}
      SubmitButton={AddItem}
      title={translations.title}
    />
  );
};

export default CreateItem;
