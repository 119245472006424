import { FC } from "react";

import styles from "./index.module.css";
import { useTranslations } from "../../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../../translations/types";

type Props = {
  label: ITranslation;
};

const Label: FC<Props> = ({ label }) => {
  const translations = useTranslations() as ICommonTranslations;

  return (
    <div className={styles.wrapper}>
      {label}
      {translations.columns}
    </div>
  );
};

export default Label;
