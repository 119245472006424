import { FC, Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ICreateItemTranslations } from "..";
import { ADMIN, DASHBOARD } from "../../../../../App";
import Button from "../../../../../Components/Common/Button";
import Notification from "../../../../../Components/Common/Notification";
import { useUserContext } from "../../../../../contexts/user/Context";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../../translations/Context";
import { ICommonTranslations } from "../../../../../translations/types";
import { IItem } from "../../../../../types";
import { getBackendUrl } from "../../../../../utils";
import getApi from "../../../../../utils/axios";
import { ISubmitButtonProps } from "../../ItemDetails";

type IComponentTranslations = ICommonTranslations & ICreateItemTranslations;

const createItem = (item: IItem) =>
  getApi().post(`${getBackendUrl()}/item`, item);

const AddItem: FC<ISubmitButtonProps> = ({
  isLoading,
  item,
  onResetItem,
  setIsLoading,
}) => {
  const translations = useTranslations("createItem") as IComponentTranslations;
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { refresh } = useUserContext();
  const { language } = useLanguageContext();

  const onCreateItem = () => {
    setIsLoading(true);
    createItem(item)
      .then(() =>
        refresh().then(() => {
          onResetItem();
          setIsNotificationVisible(true);
        })
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      {isNotificationVisible && (
        <Notification
          cancelLabel={translations.addAnother}
          confirmButtonType="black"
          confirmLabel={translations.backToDashboard}
          onClose={() => setIsNotificationVisible(false)}
          onConfirm={() => navigate(`/${language}/${ADMIN}/${DASHBOARD}`)}
        >
          {translations.itemCreatedSuccessfully}
        </Notification>
      )}
      <Button
        disabled={
          isLoading ||
          !item.name ||
          !item.availableSizes.length ||
          !item.images.length
        }
        onClick={onCreateItem}
      >
        {translations.addItem}
      </Button>
    </Fragment>
  );
};

export default AddItem;
