import { FC, Fragment, ReactNode, useState } from "react";

import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";

import Drawer from "./Drawer";
import styles from "./index.module.css";

type Props = {
  children: ReactNode;
};

const Menu: FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Fragment>
      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {children}
      </Drawer>
      <div className={styles.laptopWrapper}>{children}</div>
      <MenuOutlined
        className={styles.phoneWrapper}
        onClick={() => setIsOpen(true)}
        onKeyDown={(event) => event.key === "Escape" && setIsOpen(false)}
      />
    </Fragment>
  );
};

export default Menu;
