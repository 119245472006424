import { FC, Fragment } from "react";

import { IRegisterTranslations } from "..";
import { ICommonTranslations } from "../../../../translations/types";
import Entry from "../../Entry";
import styles from "../../index.module.css";

type Props = {
  confirmedPassword: string;
  password: string;
  setConfirmedPassword: (confirmedPassword: string) => void;
  setPassword: (password: string) => void;
  setUsername: (username: string) => void;
  translations: IRegisterTranslations & ICommonTranslations;
  username: string;
};

const Content: FC<Props> = ({
  confirmedPassword,
  password,
  setConfirmedPassword,
  setPassword,
  setUsername,
  translations,
  username,
}) => (
  <Fragment>
    <Entry
      inputProps={{
        autoComplete: "off",
        onChange: (event) => setUsername(event.target.value),
        value: username,
      }}
      label={translations.username}
    />
    <Entry
      inputProps={{
        autoComplete: "off",
        onChange: (event) => setPassword(event.target.value),
        type: "password",
        value: password,
      }}
      label={translations.password}
    />
    <Entry
      inputProps={{
        autoComplete: "off",
        onChange: (event) => setConfirmedPassword(event.target.value),
        type: "password",
        value: confirmedPassword,
      }}
      label={translations.confirmPassword}
    />
    {password !== confirmedPassword && (
      <div className={styles.error}>{translations.passwordsDoNotMatch}</div>
    )}
  </Fragment>
);

export default Content;
