import { FC } from "react";

import SmileOutlined from "@ant-design/icons/SmileOutlined";

import styles from "./index.module.css";
import BackToPageButton from "../../../Components/BackToPageButton";
import { useTranslations } from "../../../translations/Context";
import { ITranslation } from "../../../translations/types";

export type IPageNotFoundTranslations = {
  pageNotFound: ITranslation;
};

const PageNotFound: FC = () => {
  const translations = useTranslations(
    "pageNotFound"
  ) as IPageNotFoundTranslations;
  return (
    <div className={styles.wrapper}>
      <SmileOutlined rotate={180} />
      <div className={styles.statusCode}>404</div>
      <div className={styles.text}>{translations.pageNotFound}</div>
      <BackToPageButton />
    </div>
  );
};

export default PageNotFound;
