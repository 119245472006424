import { FC } from "react";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";

import { IPaymentStepTranslations } from "..";
import { PAYMENT_SUCCESS, SHOP } from "../../../../../App";
import Button from "../../../../../Components/Common/Button";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../../translations/Context";
import { ILanguage } from "../../../../../translations/types";
import { IAddress } from "../../../../../types";

const getReturnUrl = (language: string): string =>
  `${window.location.origin}/${language}/${SHOP}/${PAYMENT_SUCCESS}`;

const handleSubmit = ({
  elements,
  email,
  language,
  setIsLoading,
  setMessage,
  shippingAddress,
  stripe,
}: {
  elements: StripeElements | null;
  email: string;
  language: ILanguage;
  setIsLoading: (isLoading: boolean) => void;
  setMessage: (message: string | null) => void;
  shippingAddress: IAddress;
  stripe: Stripe | null;
}) => {
  if (!stripe || !elements) {
    return;
  }

  setIsLoading(true);

  stripe
    .confirmPayment({
      confirmParams: {
        receipt_email: email,
        return_url: getReturnUrl(language),
        shipping: {
          address: {
            city: shippingAddress.city,
            country: shippingAddress.country,
            line1: shippingAddress.line1,
            line2: shippingAddress.line2 || undefined,
            postal_code: shippingAddress.postal_code,
            state: shippingAddress.state,
          },
          name: `${shippingAddress.firstName} ${shippingAddress.lastName}`,
          phone: shippingAddress.phone,
        },
      },
      elements,
    })
    .then(({ error }) => {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error?.message || null);
      } else {
        setMessage("An unexpected error occurred.");
      }
    })
    .finally(() => setIsLoading(false));
};

type Props = {
  email: string;
  isLoading: boolean;
  onPayment: () => Promise<AxiosResponse<string>>;
  setIsLoading: (isLoading: boolean) => void;
  setMessage: (message: string | null) => void;
  shippingAddress: IAddress;
};

const CheckoutButton: FC<Props> = ({
  email,
  isLoading,
  onPayment,
  shippingAddress,
  setIsLoading,
  setMessage,
}) => {
  const { language } = useLanguageContext();
  const stripe = useStripe();
  const elements = useElements();
  const translations = useTranslations(
    "paymentStepTranslations"
  ) as IPaymentStepTranslations;

  return (
    <Button
      buttonType="black"
      disabled={isLoading || !stripe || !elements}
      isLoading={isLoading}
      onClick={() =>
        onPayment().then(() =>
          handleSubmit({
            elements,
            email,
            language,
            setIsLoading,
            setMessage,
            shippingAddress,
            stripe,
          })
        )
      }
    >
      {translations.payNow}
    </Button>
  );
};

export default CheckoutButton;
