import { FC, Fragment, ReactNode, useEffect, useRef } from "react";

import styles from "./index.module.css";
import { useTranslations } from "../../../translations/Context";
import { ITranslation } from "../../../translations/types";
import Button, { IButtonType } from "../Button";
import Mask from "../Mask";

export type INotificationTranslations = {
  cancel: ITranslation;
  confirm: ITranslation;
};

type Props = {
  cancelLabel?: ITranslation;
  children: ReactNode;
  className?: string;
  confirmButtonType?: IButtonType;
  confirmLabel?: ITranslation;
  onClose: () => void;
  onConfirm?: () => void;
  title?: ReactNode;
};

const Notification: FC<Props> = ({
  cancelLabel,
  children,
  className = "",
  confirmButtonType = "danger",
  confirmLabel,
  onClose,
  onConfirm,
  title,
}) => {
  const translations = useTranslations(
    "notification"
  ) as INotificationTranslations;

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => divRef.current?.focus(), []);

  return (
    <Fragment>
      <Mask onClose={onClose} />
      <div
        className={`${styles.wrapper} ${className}`}
        onKeyDown={(event) => event.key === "Escape" && onClose()}
        ref={divRef}
        tabIndex={0}
      >
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.content}>{children}</div>
        {onConfirm && (
          <div className={styles.buttons}>
            <Button
              buttonType="white"
              onClick={onClose}
            >
              {cancelLabel || translations.cancel}
            </Button>
            <Button
              buttonType={confirmButtonType}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {confirmLabel || translations.confirm}
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Notification;
