import { FC } from "react";

import styles from "./index.module.css";
import { ABOUT_US } from "../../App";
import { useTranslations } from "../../translations/Context";
import { ITranslation } from "../../translations/types";
import Link from "../Common/Link";

export type IFooterTranslations = {
  content: ITranslation;
  aboutUs: ITranslation;
};

const Footer: FC = () => {
  const translations = useTranslations("footer") as IFooterTranslations;
  return (
    <div className={styles.wrapper}>
      <div>{translations.content}</div>
      <Link
        buttonType="black"
        route={ABOUT_US}
      >
        <div className={styles.aboutUs}>{translations.aboutUs}</div>
      </Link>
    </div>
  );
};

export default Footer;
