import { FC } from "react";

import styles from "./index.module.css";
import { ITranslation } from "../../../../../translations/types";

type Props = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  text: ITranslation;
};

const Checkbox: FC<Props> = ({ isChecked, setIsChecked, text }) => (
  <div className={styles.wrapper}>
    <input
      onChange={() => setIsChecked(!isChecked)}
      checked={isChecked}
      type="checkbox"
    />
    <div onClick={() => setIsChecked(!isChecked)}>{text}</div>
  </div>
);

export default Checkbox;
