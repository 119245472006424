import { FC, Fragment, InputHTMLAttributes } from "react";

import styles from "./index.module.css";
import { ITranslation } from "../../../translations/types";

type Props = {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  label: ITranslation;
};

const Entry: FC<Props> = ({ inputProps, label }) => (
  <Fragment>
    <div className={styles.label}>{label}</div>
    <input
      {...inputProps}
      className={styles.input}
    />
  </Fragment>
);

export default Entry;
