import { FC, Fragment } from "react";

import { Outlet, useNavigation } from "react-router-dom";

import styles from "./index.module.css";
import { useLoadingContext } from "../../contexts/loading/Context";
import LoadingPage from "../Common/LoadingPage";
import Footer from "../Footer";
import Header from "../Header";

const Content: FC = () => {
  const { isFetching } = useLoadingContext();

  const navigation = useNavigation();

  return (
    <Fragment>
      <Header />
      <div className={styles.body}>
        <div className={styles.wrapper}>
          {(isFetching || navigation.state !== "idle") && <LoadingPage />}
          <Outlet />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Content;
