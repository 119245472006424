import axios from "axios";

export const ACCESS_TOKEN = "accessToken";

export const getHeaders = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    return {
      authorization: `Bearer ${token}`,
    };
  }
  return undefined;
};

const unauthenticatedHandler = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  throw {
    message: "Vous avez été déconnecté. Nous allons vous rediriger.",
    status: 401,
  };
};

const getApi = () => {
  const api = axios.create({
    headers: getHeaders(),
  });

  api.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        unauthenticatedHandler();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return api;
};

export default getApi;
