import { FC, useEffect } from "react";

import { useLoaderData } from "react-router-dom";

import styles from "./index.module.css";
import { DASHBOARD, SHOP, USER } from "../../../../App";
import Link from "../../../../Components/Common/Link";
import { useUserContext } from "../../../../contexts/user/Context";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";
import { clearCart } from "../../Cart/utils";

export type IPaymentSuccessTranslations = {
  findYourOrdersHere: ITranslation;
  paymentSuccess: ITranslation;
  yourOrderId: (id: string) => ITranslation;
  youWillGetAnEmail: ITranslation;
};

const PaymentSuccess: FC = () => {
  const { user } = useUserContext();
  const { data: orderId } = useLoaderData() as { data: string };
  const translations = useTranslations(
    "paymentSuccess"
  ) as IPaymentSuccessTranslations;

  useEffect(() => clearCart(), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{translations.paymentSuccess}</div>
      <div>{translations.yourOrderId(orderId)}</div>
      <div>{translations.youWillGetAnEmail}</div>
      {user && (
        <Link
          buttonType="text"
          route={`${SHOP}/${USER}/${DASHBOARD}`}
        >
          {translations.findYourOrdersHere}
        </Link>
      )}
    </div>
  );
};

export default PaymentSuccess;
