import { FC } from "react";

import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import LeftOutlined from "@ant-design/icons/LeftOutlined";

import Button from "../../../../../../../Components/Common/Button";
import styles from "../index.module.css";

type Props = {
  onQuantityEdition: (quantity: number) => void;
  onRemove?: () => void;
  quantity: number;
  setIsNotificationVisible: (isVisible: boolean) => void;
};

const LeftIcon: FC<Props> = ({
  onQuantityEdition,
  onRemove,
  quantity,
  setIsNotificationVisible,
}) => {
  if (onRemove && quantity === 1) {
    return (
      <Button
        buttonType="text"
        className={styles.icon}
        onClick={() => setIsNotificationVisible(true)}
      >
        <DeleteOutlined />
      </Button>
    );
  }
  return (
    <Button
      buttonType="text"
      className={styles.icon}
      disabled={quantity === 1}
      onClick={() => quantity > 1 && onQuantityEdition(quantity - 1)}
    >
      <LeftOutlined />
    </Button>
  );
};

export default LeftIcon;
