import { FC } from "react";

import styles from "./index.module.css";
import { IItemDetailsTranslation, ISubmitButtonProps } from "..";
import { ADMIN, DASHBOARD } from "../../../../../App";
import BackToPageButton from "../../../../../Components/BackToPageButton";
import { useTranslations } from "../../../../../translations/Context";
import { IItem } from "../../../../../types";

type Props = {
  isLoading: boolean;
  onResetItem: () => void;
  setIsLoading: (isLoading: boolean) => void;
  SubmitButton: FC<ISubmitButtonProps>;
  toEditItem: IItem;
};

const Actions: FC<Props> = ({
  isLoading,
  onResetItem,
  setIsLoading,
  SubmitButton,
  toEditItem,
}) => {
  const translations = useTranslations(
    "itemDetails"
  ) as IItemDetailsTranslation;

  return (
    <div className={styles.wrapper}>
      <BackToPageButton
        buttonType="white"
        label={translations.back}
        route={`${ADMIN}/${DASHBOARD}`}
      />
      <SubmitButton
        isLoading={isLoading}
        item={toEditItem}
        onResetItem={onResetItem}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default Actions;
