import { FC, useState } from "react";

import { useLoaderData } from "react-router-dom";

import AvailableSizes from "./AvailableSizes";
import Field from "./Field";
import styles from "./index.module.css";
import PhotoGallery from "./PhotoGallery";
import { IGetElements } from "./PhotoGallery/Thumbnails";
import Price from "../../../Components/Price";
import { useTranslations } from "../../../translations/Context";
import { ICommonTranslations } from "../../../translations/types";
import { IImage, IItem } from "../../../types";
import EditableQuantities from "../Cart/Items/Item/EditableQuantities";
import AddToCart from "../ItemsList/Item/AddToCart";

const getThumbnailElements =
  (images: IImage[]) =>
  ({ getClassName, getOnMouseEnter }: IGetElements) =>
    images.map((image, index) => (
      <img
        className={getClassName(index)}
        key={image.publicId}
        onClick={(event) => event.stopPropagation()}
        onMouseEnter={getOnMouseEnter(index)}
        src={image.url}
      />
    ));

const ItemDetail: FC = () => {
  const { data } = useLoaderData() as { data: IItem };
  const [selectedSize, setSelectedSize] = useState<string>(
    data.availableSizes[0]
  );
  const [quantity, setQuantity] = useState<number>(1);
  const translations = useTranslations() as ICommonTranslations;

  return (
    <div className={styles.wrapper}>
      <PhotoGallery
        getThumbnailElements={getThumbnailElements(data.images)}
        imageUrls={data.images.map((image) => image.url)}
      />
      <div>
        <div className={styles.name}>{data.name}</div>
        <div className={styles.fields}>
          <Field name={translations.availableSizes}>
            <AvailableSizes
              availableSizes={data.availableSizes}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSize}
            />
          </Field>
          <Field name={translations.quantity}>
            <EditableQuantities
              className={styles.editableQuantities}
              onQuantityEdition={setQuantity}
              quantity={quantity}
            />
          </Field>
          <Field name={translations.unitPrice}>
            <Price price={data.price} />
          </Field>
          <AddToCart
            {...data}
            quantity={quantity}
            size={selectedSize}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
