import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { IUser } from "../../types";
import { getBackendUrl } from "../../utils";
import getApi, { ACCESS_TOKEN } from "../../utils/axios";
import { useLoadingContext } from "../loading/Context";

const UserContext = createContext<
  | {
      logout: () => void;
      refresh: () => Promise<void>;
      user: IUser | null | undefined;
    }
  | undefined
>(undefined);

const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | null | undefined>(null);
  const { setIsFetching } = useLoadingContext();
  const navigate = useNavigate();

  const refresh = () =>
    getApi()
      .get<IUser>(`${getBackendUrl()}/user`)
      .then((response) => setUser(response.data))
      .catch((error) => {
        if (error.status === 401) {
          setUser(undefined);
        }
      });

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    navigate(0);
  };

  useEffect(() => {
    setIsFetching(true);
    refresh().finally(() => setIsFetching(false));
  }, [setIsFetching]);

  return (
    <UserContext.Provider value={{ logout, refresh, user }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Context was not initialized");
  }
  return context;
};

export default UserContextProvider;
