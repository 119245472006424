import { FC } from "react";

import { Link } from "react-router-dom";

import styles from "./index.module.css";
import Button from "../../../Components/Common/Button";
import { LANGUAGES } from "../../../translations/types";

const UnknownLanguage: FC = () => (
  <div className={styles.wrapper}>
    <div>This language is unknown. Please choose one of the following:</div>
    {LANGUAGES.map((language) => (
      <Link
        className={"link"}
        key={language}
        to={`/${language}`}
      >
        <Button buttonType="black">{language}</Button>
      </Link>
    ))}
  </div>
);

export default UnknownLanguage;
