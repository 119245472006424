import { FC } from "react";

import { useLoaderData } from "react-router-dom";

import styles from "./index.module.css";
import Item from "./Item";
import { IItem } from "../../../types";

const ItemsList: FC = () => {
  const { data } = useLoaderData() as { data: IItem[]; status: number };

  return (
    <div className={styles.wrapper}>
      {data.map((item) => (
        <Item
          key={item._id}
          {...item}
        />
      ))}
    </div>
  );
};

export default ItemsList;
