import { FC } from "react";

import { Outlet, useLoaderData } from "react-router-dom";

import { useUserContext } from "../../contexts/user/Context";
import { IOrder, IUser } from "../../types";

export type IUserDashboardContext = {
  orders: IOrder[];
  user: IUser;
};

const OrdersContext: FC = () => {
  const { user } = useUserContext();
  const { data: orders } = useLoaderData() as { data: IOrder[] };

  return <Outlet context={{ orders, user }} />;
};

export default OrdersContext;
