import { FC } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { ADMIN, LOGIN, SHOP } from "../../App";
import { useUserContext } from "../../contexts/user/Context";
import { useLanguageContext } from "../../translations/Context";
import NotAdminPage from "../ErrorPages/NotAdminPage";

const getRedirectUrl = ({
  isAdmin,
  language,
}: {
  isAdmin: boolean;
  language: string;
}) => {
  if (isAdmin) {
    return `/${language}/${ADMIN}/${LOGIN}`;
  } else {
    return `/${language}/${SHOP}/${LOGIN}`;
  }
};

type Props = {
  isAdmin: boolean;
};

const CheckLoginPage: FC<Props> = ({ isAdmin }) => {
  const { language } = useLanguageContext();
  const { user } = useUserContext();

  if (user === undefined) {
    return (
      <div>
        <Navigate to={getRedirectUrl({ isAdmin, language })} />
      </div>
    );
  }

  if (user === null) {
    return null;
  }

  if (isAdmin && !user.isAdmin) {
    return <NotAdminPage />;
  }

  return <Outlet context={{ user }} />;
};

export default CheckLoginPage;
