import { FC, useEffect, useState } from "react";

import styles from "./index.module.css";
import { IStep } from "..";
import { useLoadingContext } from "../../../../../contexts/loading/Context";
import { IAddress, IUser } from "../../../../../types";
import { getBackendUrl } from "../../../../../utils";
import getApi from "../../../../../utils/axios";
import BillingAddress from "../BillingAddress";
import Buttons from "../Buttons";
import Checkout from "../Checkout";
import Email from "../Email";
import ShippingAddress from "../ShippingAddress";

const DEFAULT_ADDRESS: IAddress = {
  city: "",
  country: "",
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  phone: "",
  postal_code: "",
  state: "",
};

const onPayment = ({
  billingAddress,
  email,
  isSavingPaymentPreferences,
  orderId,
  shippingAddress,
  userId,
}: {
  billingAddress: IAddress;
  email: string;
  isSavingPaymentPreferences: boolean;
  orderId: string;
  shippingAddress: IAddress;
  userId?: string;
}) =>
  getApi().put<string>(`${getBackendUrl()}/payment/order/${orderId}`, {
    billingAddress,
    email,
    isSavingPaymentPreferences,
    shippingAddress,
    userId,
  });

type StepProps = {
  billingAddress: IAddress;
  email: string;
  isLoading: boolean;
  isSameBillingAddress: boolean;
  isSavingPaymentPreferences: boolean;
  message: string | null;
  orderId: string;
  setBillingAddress: (billingAddress: IAddress) => void;
  setEmail: (email: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsSameBillingAddress: (isSameBillingAddress: boolean) => void;
  setIsSavingPaymentPreferences: (isSavingPaymentPreferences: boolean) => void;
  setMessage: (message: string | null) => void;
  setShippingAddress: (shippingAddress: IAddress) => void;
  setStep: (step: IStep) => void;
  shippingAddress: IAddress;
  step: IStep;
  user?: IUser | null;
};

const getStep = (props: StepProps) => {
  let StepComponent;
  switch (props.step) {
    case "BILLING_ADDRESS":
      StepComponent = <BillingAddress {...props} />;
      break;
    case "CREDIT_CARD":
      StepComponent = <Checkout {...props} />;
      break;
    case "EMAIL":
      StepComponent = <Email {...props} />;
      break;
    case "SHIPPING_ADDRESS":
      StepComponent = <ShippingAddress {...props} />;
      break;
    default:
      throw new Error("Unexpected");
  }
  return (
    <div className={styles.wrapper}>
      {StepComponent}
      <Buttons
        {...props}
        onPayment={() =>
          onPayment({
            ...props,
            billingAddress: props.isSameBillingAddress
              ? props.shippingAddress
              : props.billingAddress,
            userId: props.user?._id,
          })
        }
      />
    </div>
  );
};

type Props = {
  orderId: string;
  setStep: (step: IStep) => void;
  step: IStep;
  user?: IUser | null;
};

const Step: FC<Props> = ({ orderId, setStep, step, user }) => {
  const [billingAddress, setBillingAddress] = useState<IAddress>(
    user?.billingAddresses[0] || DEFAULT_ADDRESS
  );
  const [shippingAddress, setShippingAddress] = useState<IAddress>(
    user?.shippingAddresses[0] || DEFAULT_ADDRESS
  );
  const [isSavingPaymentPreferences, setIsSavingPaymentPreferences] =
    useState<boolean>(true);
  const { isFetching: isLoading, setIsFetching: setIsLoading } =
    useLoadingContext();

  const [isSameBillingAddress, setIsSameBillingAddress] =
    useState<boolean>(true);
  const [email, setEmail] = useState<string>(user?.email || "");
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => setIsLoading(true), [setIsLoading]);

  return getStep({
    billingAddress,
    email,
    isLoading,
    isSameBillingAddress,
    isSavingPaymentPreferences,
    message,
    orderId,
    setBillingAddress,
    setEmail,
    setIsLoading,
    setIsSameBillingAddress,
    setIsSavingPaymentPreferences,
    setMessage,
    setShippingAddress,
    setStep,
    shippingAddress,
    step,
    user,
  });
};

export default Step;
