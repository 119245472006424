import { FC } from "react";

import { LinkAuthenticationElement } from "@stripe/react-stripe-js";

type Props = {
  email: string;
  setEmail: (email: string) => void;
  setIsLoading: (isLoading: boolean) => void;
};

const Email: FC<Props> = ({ email, setEmail, setIsLoading }) => (
  <LinkAuthenticationElement
    id="link-authentication-element"
    onChange={(event) => setEmail(event.value.email)}
    onReady={() => setIsLoading(false)}
    options={{
      defaultValues: {
        email: email,
      },
    }}
  />
);

export default Email;
