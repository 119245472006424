import { FC, Fragment, ReactNode } from "react";

import styles from "./index.module.css";
import Mask from "../../../Common/Mask";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const Drawer: FC<Props> = ({ children, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Fragment>
      <Mask
        className={styles.mask}
        onClose={onClose}
      />
      <div className={styles.wrapper}>
        <div
          className={styles.closeIcon}
          onClick={onClose}
        >
          X
        </div>
        <div
          className={styles.children}
          onClick={onClose}
        >
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default Drawer;
