import { FC } from "react";

import { useOutletContext } from "react-router-dom";

import Attribute from "./Attribute";
import styles from "./index.module.css";
import { IUserDashboardContext } from "../..";
import { DASHBOARD, SHOP, USER } from "../../../../App";
import Link from "../../../../Components/Common/Link";
import Section from "../../../../Components/Dashboard/Section";
import Price from "../../../../Components/Price";
import { useTranslations } from "../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../translations/types";
import { formatDate } from "../../../../utils";

export type IOrdersTranslations = {
  itemsNumber: ITranslation;
  noOrder: ITranslation;
  title: ITranslation;
};

const Orders: FC = () => {
  const translations = useTranslations("orders") as IOrdersTranslations &
    ICommonTranslations;

  const { orders } = useOutletContext() as IUserDashboardContext;

  const payedOrders = orders.filter(
    (order) => order.paymentIntent.status === "succeeded"
  );

  return (
    <Section
      className={styles.section}
      title={translations.title}
    >
      {!payedOrders?.length
        ? translations.noOrder
        : payedOrders.map((order) => (
            <Link
              className={styles.order}
              key={order.paymentIntent.id}
              route={`${SHOP}/${USER}/${DASHBOARD}/${order._id}`}
            >
              <Attribute title={translations.quantity}>
                <div>{formatDate(order.orderDate)}</div>
              </Attribute>
              <Attribute title={translations.itemsNumber}>
                <div>{order.orderedItems.length}</div>
              </Attribute>
              <Attribute title={translations.price}>
                <Price price={order.price / 100} />
              </Attribute>
            </Link>
          ))}
    </Section>
  );
};

export default Orders;
