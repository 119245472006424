import { FC, Fragment } from "react";

import styles from "./index.module.css";
import SizeInput from "./SizeInput";
import { useTranslations } from "../../../../../translations/Context";
import Label from "../Label";

type Props = {
  availableSizes: string[];
  setAvailableSizes: (sizes: string[]) => void;
};

const AvailableSizes: FC<Props> = ({ availableSizes, setAvailableSizes }) => {
  const translations = useTranslations();
  return (
    <Fragment>
      <Label label={translations.availableSizes} />
      <div className={styles.wrapper}>
        <SizeInput
          availableSizes={availableSizes}
          setAvailableSizes={setAvailableSizes}
        />
        <div className={styles.sizes}>
          {availableSizes.map((availableSize, index) => (
            <div
              className={styles.size}
              key={availableSize}
              onClick={() =>
                setAvailableSizes(
                  availableSizes.filter((size) => size !== availableSize)
                )
              }
            >
              {availableSize}
              {index !== availableSizes.length - 1 && ","}
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default AvailableSizes;
