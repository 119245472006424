import { FC, Fragment } from "react";

import { useTranslations } from "../../../../../translations/Context";
import Label from "../Label";

type Props = {
  price: number;
  setPrice: (price: number) => void;
};

const Price: FC<Props> = ({ price, setPrice }) => {
  const translations = useTranslations();

  return (
    <Fragment>
      <Label label={translations.price} />
      <input
        onChange={(event) => setPrice(Number.parseFloat(event.target.value))}
        min={0}
        step={0.01}
        type="number"
        value={price}
      />
    </Fragment>
  );
};

export default Price;
