import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { loadStripe, Stripe } from "@stripe/stripe-js";

import { getBackendUrl } from "../../utils";
import getApi from "../../utils/axios";
import { useLoadingContext } from "../loading/Context";

type IEnvironmentVariables = {
  stripePrimaryKey: string;
};

const EnvironmentContext = createContext<
  | {
      stripePromise: Promise<Stripe | null>;
    }
  | undefined
>(undefined);

const EnvironmentContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setIsFetching } = useLoadingContext();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(
    Promise.resolve(null)
  );

  useEffect(() => {
    setIsFetching(true);
    getApi()
      .get<IEnvironmentVariables>(`${getBackendUrl()}/environment`)
      .then(({ data }) =>
        setStripePromise(loadStripe(data.stripePrimaryKey || ""))
      )
      .finally(() => setIsFetching(false));
  }, [setIsFetching]);

  return (
    <EnvironmentContext.Provider value={{ stripePromise }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error("Context was not initialized");
  }
  return context;
};

export default EnvironmentContextProvider;
