import { FC, Fragment, useEffect, useState } from "react";

import Actions from "./Actions";
import EmptyCart from "./EmptyCart";
import styles from "./index.module.css";
import Items from "./Items";
import TotalPrice from "./TotalPrice";
import { CART_EDITION_EVENT, getCartItems, getItemsPrice } from "./utils";
import { useTranslations } from "../../../translations/Context";
import { ICommonTranslations, ITranslation } from "../../../translations/types";
import { ICartItem } from "../../../types";

export type ICartTranslations = {
  title: ITranslation;
};

const Cart: FC = () => {
  const [items, setItems] = useState<ICartItem[]>(getCartItems());

  useEffect(() => {
    const onCartEdition = () => setItems(getCartItems());

    window.addEventListener(CART_EDITION_EVENT, onCartEdition);

    return () => {
      window.removeEventListener(CART_EDITION_EVENT, onCartEdition);
    };
  }, []);

  const translations = useTranslations("cart") as ICartTranslations &
    ICommonTranslations;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{translations.title}</div>
      <div className={styles.content}>
        {items.length === 0 ? (
          <EmptyCart />
        ) : (
          <Fragment>
            <Items items={items} />
            <TotalPrice price={getItemsPrice(items)} />
            <Actions />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Cart;
