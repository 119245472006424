import { FC, Fragment, ReactNode, useState } from "react";

import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import styles from "./index.module.css";
import RemoveLineNotification from "./RemoveLineNotification";
import Button from "../../../../../Components/Common/Button";

interface Props {
  className?: string;
  linePrice: ReactNode;
  name: ReactNode;
  onRemove?: () => void;
  quantity: ReactNode;
  size: ReactNode;
  unitPrice: ReactNode;
}

const Item: FC<Props> = ({
  className = "",
  linePrice,
  name,
  onRemove,
  quantity,
  size,
  unitPrice,
}) => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  return (
    <Fragment>
      {isNotificationVisible && (
        <RemoveLineNotification
          onClose={() => setIsNotificationVisible(false)}
          onRemove={onRemove}
        />
      )}
      <div className={styles.wrapper}>
        <div className={`${styles.fields} ${className}`}>
          <div className={styles.name}>{name}</div>
          <div className={styles.size}>{size}</div>
          <div className={styles.quantity}>{quantity}</div>
          <div className={styles.unitPrice}>{unitPrice}</div>
          <div className={styles.linePrice}>{linePrice}</div>
        </div>
        {onRemove && (
          <Button buttonType="text">
            <DeleteOutlined
              className={styles.removeLine}
              onClick={() => setIsNotificationVisible(true)}
            />
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default Item;
