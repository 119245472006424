import { FC, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";

import Steps from "./Steps";
import { useEnvironmentContext } from "../../../contexts/environment/Context";
import { useLoadingContext } from "../../../contexts/loading/Context";
import { useUserContext } from "../../../contexts/user/Context";
import { getBackendUrl } from "../../../utils";
import getApi from "../../../utils/axios";
import { getCartItems, getCartPrice } from "../Cart/utils";

const Payment: FC = () => {
  const { user } = useUserContext();
  const { stripePromise } = useEnvironmentContext();

  const [paymentCreationResponse, setPaymentCreationResponse] = useState<{
    clientSecret: string;
    orderId: string;
  }>();

  const { setIsFetching } = useLoadingContext();

  useEffect(() => {
    setIsFetching(true);
    getApi()
      .post(`${getBackendUrl()}/payment/create-payment-intent`, {
        items: getCartItems(),
        uiAmount: getCartPrice(),
        userId: user?._id,
      })
      .then((res) => setPaymentCreationResponse(res.data))
      .finally(() => setIsFetching(false));
  }, [setIsFetching, user]);

  if (!paymentCreationResponse) {
    return null;
  }

  return (
    <Elements
      options={{ clientSecret: paymentCreationResponse.clientSecret }}
      stripe={stripePromise}
    >
      <Steps
        orderId={paymentCreationResponse.orderId}
        user={user}
      />
    </Elements>
  );
};

export default Payment;
