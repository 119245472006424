import { FC } from "react";

import styles from "./index.module.css";
import Price from "../../../../Components/Price";
import { useTranslations } from "../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../translations/types";
import Item from "../Items/Item";

export type ITotalPriceTranslations = {
  totalPrice: ITranslation;
};

type Props = {
  price: number;
};

const TotalPrice: FC<Props> = ({ price }) => {
  const translations = useTranslations(
    "totalPrice"
  ) as ITotalPriceTranslations & ICommonTranslations;
  return (
    <Item
      className={styles.wrapper}
      linePrice={<Price price={price} />}
      name=""
      quantity=""
      size=""
      unitPrice={
        <div className={styles.totalPrice}>
          {translations.totalPrice}
          {translations.columns}
        </div>
      }
    />
  );
};

export default TotalPrice;
