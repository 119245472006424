import { FC } from "react";

import styles from "./index.module.css";
import Button from "../../../../Components/Common/Button";

type Props = {
  availableSizes: string[];
  selectedSize: string;
  setSelectedSize: (size: string) => void;
};

const AvailableSizes: FC<Props> = ({
  availableSizes,
  selectedSize,
  setSelectedSize,
}) => (
  <div className={styles.wrapper}>
    {availableSizes.map((size) => (
      <Button
        buttonType="text"
        className={styles.size}
        disabled={selectedSize === size}
        key={size}
        onClick={() => setSelectedSize(size)}
      >
        {size}
      </Button>
    ))}
  </div>
);

export default AvailableSizes;
