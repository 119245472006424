import { FC } from "react";

import styles from "./index.module.css";
import { ISize } from "../../types";

type Props = {
  availableSizes: ISize[];
  className?: string;
  setSize: (size: ISize) => void;
  size: ISize;
};

const SizePicker: FC<Props> = ({
  availableSizes,
  className = "",
  setSize,
  size,
}) => {
  return (
    <select
      className={`${styles.wrapper} ${className}`}
      onChange={(event) => setSize(event.target.value)}
      value={size}
    >
      {availableSizes.map((availableSize) => (
        <option
          key={availableSize}
          value={availableSize}
        >
          {availableSize}
        </option>
      ))}
    </select>
  );
};

export default SizePicker;
