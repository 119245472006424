import { FC, ReactNode } from "react";

import styles from "./index.module.css";
import { useTranslations } from "../../../../../translations/Context";
import { ITranslation } from "../../../../../translations/types";

type Props = {
  children: ReactNode;
  title: ITranslation;
};

const Attribute: FC<Props> = ({ children, title }) => {
  const translations = useTranslations();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
        {translations.columns}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Attribute;
