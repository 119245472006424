import { FC, ReactNode } from "react";

import { IButtonType } from "../Common/Button";
import Link from "../Common/Link";

type Props = {
  buttonType: IButtonType;
  text: ReactNode;
};

const ToItemsLink: FC<Props> = ({ buttonType, text }) => {
  return (
    <Link
      buttonType={buttonType}
      route=""
    >
      {text}
    </Link>
  );
};

export default ToItemsLink;
