import { FC } from "react";

import styles from "./index.module.css";

type Props = {
  message?: string | null;
};

const ErrorMessage: FC<Props> = ({ message }) => {
  if (!message) {
    return null;
  }
  return <div className={styles.wrapper}>{message}</div>;
};

export default ErrorMessage;
