import { FC, Fragment } from "react";

import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";

import { useUserContext } from "../../../contexts/user/Context";
import { useTranslations } from "../../../translations/Context";
import Link from "../Link";

export type IUserTranslations = {
  connection: string;
  logout: string;
};

type Props = {
  dashboardUrl: string;
  loginUrl: string;
};

const User: FC<Props> = ({ dashboardUrl, loginUrl }) => {
  const { logout, user } = useUserContext();

  const translations = useTranslations("user") as IUserTranslations;

  return (
    <Fragment>
      <Link
        icon={<UserOutlined />}
        route={user ? dashboardUrl : loginUrl}
        text={user?.username || translations.connection}
      />
      {user && (
        <Link
          icon={<LogoutOutlined />}
          onClick={logout}
          route=""
          text={translations.logout}
        />
      )}
    </Fragment>
  );
};

export default User;
