import { FC, Fragment } from "react";

import { useOutletContext, useParams } from "react-router-dom";

import styles from "./index.module.css";
import Line from "./Line";
import { IUserDashboardContext } from "../../..";
import { DASHBOARD, SHOP, USER } from "../../../../../App";
import BackToPageButton from "../../../../../Components/BackToPageButton";
import Price from "../../../../../Components/Price";
import { useTranslations } from "../../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../../translations/types";
import { formatDate } from "../../../../../utils";
import PageNotFound from "../../../../ErrorPages/PageNotFound";
import Attribute from "../Attribute";

export type IOrderTranslations = {
  backToOrders: ITranslation;
  item: ITranslation;
  status: ITranslation;
  title: ITranslation;
  totalPrice: ITranslation;
};

const Order: FC = () => {
  const { orderId } = useParams();
  const { orders } = useOutletContext() as IUserDashboardContext;

  const translations = useTranslations("order") as IOrderTranslations &
    ICommonTranslations;

  const order = orders.find((currentOrder) => currentOrder._id === orderId);

  if (!order) {
    return <PageNotFound />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <div>{translations.title}</div>
        <div className={styles.orderId}>{orderId}</div>
      </div>
      <div className={styles.infos}>
        <div className={styles.articles}>
          <div className={`${styles.row} ${styles.header}`}>
            <div className={styles.product}>{translations.item}</div>
            <div className={styles.quantity}>{translations.quantity}</div>
            <div className={styles.unitPrice}>{translations.unitPrice}</div>
          </div>
          {order.orderedItems.map((item) => (
            <Line
              className={styles.row}
              item={item}
              key={item._id + item.size}
            />
          ))}
        </div>
        <div className={styles.attributes}>
          <Attribute title={<Fragment>{translations.date}</Fragment>}>
            {formatDate(order.orderDate)}
          </Attribute>
          <Attribute title={<Fragment>{translations.totalPrice}</Fragment>}>
            <Price price={order.price / 100} />
          </Attribute>
          <Attribute title={<Fragment>{translations.status}</Fragment>}>
            <div className={styles.status}>{order.paymentIntent.status}</div>
          </Attribute>
        </div>
      </div>
      <BackToPageButton
        label={translations.backToOrders}
        route={`${SHOP}/${USER}/${DASHBOARD}`}
      />
    </div>
  );
};

export default Order;
