import { FC, Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ADMIN, DASHBOARD } from "../../../../../../App";
import Button from "../../../../../../Components/Common/Button";
import Notification from "../../../../../../Components/Common/Notification";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../../../translations/Context";
import {
  ICommonTranslations,
  ITranslation,
} from "../../../../../../translations/types";
import { IItem } from "../../../../../../types";
import { getBackendUrl } from "../../../../../../utils";
import getApi from "../../../../../../utils/axios";

export type IUpdateButtonTranslations = {
  close: ITranslation;
  update: ITranslation;
  updatedSuccessfullyDescription: ITranslation;
};

type Props = {
  isLoading: boolean;
  item: IItem;
  setIsLoading: (isLoading: boolean) => void;
};

const Update: FC<Props> = ({ isLoading, item, setIsLoading }) => {
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);

  const translations = useTranslations("updateButton") as ICommonTranslations &
    IUpdateButtonTranslations;

  const { language } = useLanguageContext();
  const navigate = useNavigate();

  return (
    <Fragment>
      {isNotificationVisible && (
        <Notification
          cancelLabel={translations.close}
          confirmButtonType="black"
          confirmLabel={translations.backToDashboard}
          onClose={() => setIsNotificationVisible(false)}
          onConfirm={() => navigate(`/${language}/${ADMIN}/${DASHBOARD}`)}
        >
          {translations.updatedSuccessfullyDescription}
        </Notification>
      )}
      <Button
        disabled={
          isLoading ||
          !item.availableSizes.length ||
          !item.images.length ||
          !item.name ||
          !item.price
        }
        onClick={() => {
          setIsLoading(true);
          getApi()
            .put(`${getBackendUrl()}/item/${item._id}`, item)
            .then(() => setIsNotificationVisible(true))
            .finally(() => setIsLoading(false));
        }}
      >
        {translations.update}
      </Button>
    </Fragment>
  );
};

export default Update;
