import { FC, Fragment } from "react";

import Link from "../../../../Components/Common/Link";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";

export type ILoginFooterTranslations = {
  register: ITranslation;
  youAreNew: ITranslation;
};

type Props = {
  pathToRegister: string;
};

const Footer: FC<Props> = ({ pathToRegister }) => {
  const translations = useTranslations(
    "loginFooter"
  ) as ILoginFooterTranslations;

  return (
    <Fragment>
      <div>{translations.youAreNew}</div>
      <Link
        buttonType="text"
        route={pathToRegister}
      >
        {translations.register}
      </Link>
    </Fragment>
  );
};

export default Footer;
