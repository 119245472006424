import { FC } from "react";

import { ITEM, SHOP } from "../../../../../../App";
import Link from "../../../../../../Components/Common/Link";
import Price from "../../../../../../Components/Price";
import { IOrderItem } from "../../../../../../types";
import styles from "../index.module.css";

type Props = {
  className: string;
  item: IOrderItem;
};

const Line: FC<Props> = ({ className, item }) => (
  <div className={className}>
    <div className={styles.product}>
      <Link
        buttonType="text"
        className={styles.product}
        route={`${SHOP}/${ITEM}/${item.itemId}`}
      >
        {item.name}
      </Link>
    </div>
    <div className={styles.quantity}>{item.quantity}</div>
    <div className={styles.unitPrice}>
      <Price price={item.price} />
    </div>
  </div>
);

export default Line;
