import { FC, ReactNode, useEffect, useRef } from "react";

import styles from "./index.module.css";
import Thumbnails, { IGetElements } from "../Thumbnails";

type Props = {
  currentImageIndex: number;
  getThumbnailElements: ({
    getClassName,
    getOnMouseEnter,
    setCurrentImageIndex,
  }: IGetElements) => ReactNode[];
  imageUrls: string[];
  isFullScreen: boolean;
  noImage?: ReactNode;
  onKeyDown: ({
    currentImageIndex,
    event,
  }: {
    currentImageIndex: number;
    event: React.KeyboardEvent<HTMLImageElement>;
  }) => void;
  setCurrentImageIndex: (currentIndex: number) => void;
  setIsFullScreen: (isFullScreen: boolean) => void;
};

const Photos: FC<Props> = ({
  currentImageIndex,
  getThumbnailElements,
  imageUrls,
  isFullScreen,
  noImage,
  onKeyDown,
  setCurrentImageIndex,
  setIsFullScreen,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!isFullScreen) {
      imageRef.current?.focus();
    }
  }, [isFullScreen]);

  return (
    <div
      className={styles.wrapper}
      onClick={() => setIsFullScreen(true)}
    >
      {imageUrls[currentImageIndex] ? (
        <img
          className={styles.mainImage}
          onKeyDown={(event) => onKeyDown({ currentImageIndex, event })}
          ref={imageRef}
          src={imageUrls[currentImageIndex]}
          tabIndex={0}
        />
      ) : (
        noImage
      )}
      <Thumbnails
        currentImageIndex={currentImageIndex}
        getElements={getThumbnailElements}
        setCurrentImageIndex={setCurrentImageIndex}
      />
    </div>
  );
};

export default Photos;
