import { FC, useState } from "react";

import Actions from "./Actions";
import styles from "./index.module.css";
import Name from "./Name";
import Photos from "./Photos";
import Price from "./Price";
import { useLoadingContext } from "../../../../contexts/loading/Context";
import { ITranslation } from "../../../../translations/types";
import { IItem } from "../../../../types";
import AvailableSizes from "../ItemDetails/AvailableSizes";

export type IItemDetailsTranslation = {
  addItem: ITranslation;
  back: ITranslation;
  name: ITranslation;
};

export type ISubmitButtonProps = {
  isLoading: boolean;
  item: IItem;
  onResetItem: () => void;
  setIsLoading: (isLoading: boolean) => void;
};

type Props = {
  item: IItem;
  SubmitButton: FC<ISubmitButtonProps>;
  title: ITranslation;
};

const ItemDetails: FC<Props> = ({ item, SubmitButton, title }) => {
  const [toEditItem, setToEditItem] = useState<IItem>(item);

  const { isFetching: isLoading, setIsFetching: setIsLoading } =
    useLoadingContext();

  return (
    <div className={styles.container}>
      <div className={`${styles.wrapper} ${styles.a}`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.details}>
          <Photos
            images={toEditItem.images}
            setImages={(images) => setToEditItem({ ...toEditItem, images })}
          />
          <div className={styles.form}>
            <Name
              name={toEditItem.name}
              setName={(name) => setToEditItem({ ...toEditItem, name })}
            />
            <AvailableSizes
              availableSizes={toEditItem.availableSizes}
              setAvailableSizes={(availableSizes) =>
                setToEditItem({ ...toEditItem, availableSizes })
              }
            />
            <Price
              price={toEditItem.price}
              setPrice={(price) => setToEditItem({ ...toEditItem, price })}
            />
            <Actions
              isLoading={isLoading}
              onResetItem={() => setToEditItem(item)}
              setIsLoading={setIsLoading}
              SubmitButton={SubmitButton}
              toEditItem={toEditItem}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
