import { FC, useEffect } from "react";

import { useParams, useRouteError } from "react-router-dom";

import styles from "./index.module.css";
import BackToPageButton from "../../../Components/BackToPageButton";
import { getTranslations } from "../../../translations";
import { ILanguage, ITranslation } from "../../../translations/types";

export type IDefaultErrorPageTranslations = {
  somethingWentWrong: ITranslation;
};

const DefaultErrorPage: FC = () => {
  const error = useRouteError();
  const { language } = useParams();

  useEffect(() => console.error(error), [error]);

  const translations = getTranslations({
    language: language as ILanguage,
    module: "defaultErrorPage",
  }) as IDefaultErrorPageTranslations;

  return (
    <div className={styles.wrapper}>
      <div className={styles.somethingWentWrong}>
        {translations.somethingWentWrong}
      </div>
      <BackToPageButton />
    </div>
  );
};

export default DefaultErrorPage;
