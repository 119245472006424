import { FC } from "react";

import { Link, useLocation } from "react-router-dom";

import Admin from "./Admin";
import styles from "./index.module.css";
import Language from "./Language";
import Menu from "./Menu";
import Shop from "./Shop";
import { ADMIN, SHOP } from "../../App";
import { useLanguageContext } from "../../translations/Context";
import Logo from "../Logo";

const Header: FC = () => {
  const { language } = useLanguageContext();
  const { pathname } = useLocation();

  return (
    <div className={styles.wrapper}>
      <Language />
      <Link
        className={`link ${styles.logo}`}
        to={
          pathname.includes(ADMIN)
            ? `/${language}/${ADMIN}`
            : `/${language}/${SHOP}`
        }
      >
        <Logo />
      </Link>
      <Menu>{pathname.includes(ADMIN) ? <Admin /> : <Shop />}</Menu>
    </div>
  );
};

export default Header;
