import { createContext, FC, ReactNode, useContext, useState } from "react";

const LoadingContext = createContext<
  | {
      isFetching: boolean;
      setIsFetching: (isLoading: boolean) => void;
    }
  | undefined
>(undefined);

const LoadingContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  return (
    <LoadingContext.Provider value={{ isFetching, setIsFetching }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("Context was not initialized");
  }
  return context;
};

export default LoadingContextProvider;
