import { FC, useState } from "react";

import styles from "./index.module.css";
import { PAYMENT, SHOP } from "../../../../App";
import Button from "../../../../Components/Common/Button";
import Link from "../../../../Components/Common/Link";
import Notification from "../../../../Components/Common/Notification";
import ToItemsLink from "../../../../Components/ToItemsLink";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";
import { emptyCart } from "../utils";

export type IActionsTranslations = {
  confirmEmptyCart: ITranslation;
  continueShopping: ITranslation;
  emptyCartDetails: ITranslation;
  emptyTheCart: ITranslation;
  pay: ITranslation;
};

const Actions: FC = () => {
  const translations = useTranslations("actions") as IActionsTranslations;
  const [isNotificationVisible, setIsNotificationVisible] =
    useState<boolean>(false);
  return (
    <div className={styles.wrapper}>
      {isNotificationVisible && (
        <Notification
          onClose={() => setIsNotificationVisible(false)}
          onConfirm={emptyCart}
          title={translations.confirmEmptyCart}
        >
          <div>{translations.emptyCartDetails}</div>
        </Notification>
      )}
      <Link
        buttonType="black"
        route={`${SHOP}/${PAYMENT}`}
      >
        {translations.pay}
      </Link>
      <div className={styles.cartActions}>
        <Button
          buttonType="danger"
          onClick={() => setIsNotificationVisible(true)}
        >
          {translations.emptyTheCart}
        </Button>
        <ToItemsLink
          buttonType="white"
          text={translations.continueShopping}
        />
      </div>
    </div>
  );
};

export default Actions;
