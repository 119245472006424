import { FC, useState } from "react";

import { Link } from "react-router-dom";

import AddToCart from "./AddToCart";
import styles from "./index.module.css";
import { ITEM, SHOP } from "../../../../App";
import Price from "../../../../Components/Price";
import SizePicker from "../../../../Components/SizePicker";
import { useLanguageContext } from "../../../../translations/Context";
import { IItem, ISize } from "../../../../types";

const Item: FC<IItem> = (props) => {
  const [size, setSize] = useState<ISize>(props.availableSizes[0]);
  const { language } = useLanguageContext();
  return (
    <div className={styles.wrapper}>
      <Link to={`/${language}/${SHOP}/${ITEM}/${props._id}`}>
        <img
          className={styles.image}
          src={props.images[0].url}
          width={"300px"}
        />
      </Link>
      <div>{props.name}</div>
      <SizePicker
        availableSizes={props.availableSizes}
        setSize={setSize}
        size={size}
      />
      <Price price={props.price} />
      <AddToCart
        {...props}
        quantity={1}
        size={size}
      />
    </div>
  );
};

export default Item;
