import { FC } from "react";

import { useLoaderData } from "react-router-dom";

import Buttons from "./Buttons";
import { IItem } from "../../../../types";
import ItemDetails from "../ItemDetails";

const UpdateItem: FC = () => {
  const { data } = useLoaderData() as { data: IItem };

  return (
    <ItemDetails
      item={data}
      SubmitButton={Buttons}
      title={data.name}
    />
  );
};

export default UpdateItem;
