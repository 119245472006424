import { FC } from "react";

import styles from "./index.module.css";

type Props = {
  className?: string;
};

const Logo: FC<Props> = ({ className = "" }) => (
  <div className={`${styles.wrapper} ${className}`}>T-Art Paris</div>
);

export default Logo;
