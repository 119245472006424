import { FC, useState } from "react";

import axios from "axios";

import Content from "./Content";
import Footer from "./Footer";
import Connection from "..";
import { ADMIN, LOGIN, SHOP } from "../../../App";
import { useTranslations } from "../../../translations/Context";
import { ICommonTranslations, ITranslation } from "../../../translations/types";
import { getBackendUrl } from "../../../utils";

export interface IRegisterTranslations {
  confirmPassword: ITranslation;
  passwordsDoNotMatch: ITranslation;
  title: ITranslation;
  usernameAlreadyTaken: ITranslation;
}

const getFooterUrl = (isAdmin: boolean) => {
  if (isAdmin) {
    return `${ADMIN}/${LOGIN}`;
  } else {
    return `${SHOP}/${LOGIN}`;
  }
};

type Props = {
  isAdmin: boolean;
};

const Register: FC<Props> = ({ isAdmin }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");

  const translations = useTranslations("register") as IRegisterTranslations &
    ICommonTranslations;

  return (
    <Connection
      footer={<Footer pathToLogin={getFooterUrl(isAdmin)} />}
      getErrorMessage={(status) => {
        switch (status) {
          case 0:
            return translations.usernameAlreadyTaken;
          default:
            return "Unexpected";
        }
      }}
      isButtonDisabled={
        !username ||
        !password ||
        !confirmedPassword ||
        password !== confirmedPassword
      }
      onSubmit={() =>
        axios.post(`${getBackendUrl()}/user/register`, {
          isAdmin,
          password,
          username,
        })
      }
      title={translations.title}
      urlSuffix={isAdmin ? ADMIN : ""}
    >
      <Content
        confirmedPassword={confirmedPassword}
        password={password}
        setConfirmedPassword={setConfirmedPassword}
        setPassword={setPassword}
        setUsername={setUsername}
        translations={translations}
        username={username}
      />
    </Connection>
  );
};

export default Register;
