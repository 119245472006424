import { FC } from "react";

import styles from "./index.module.css";

type Props = {
  className?: string;
  onClose?: () => void;
};

const Mask: FC<Props> = ({ className = "", onClose = () => {} }) => (
  <div
    className={`${styles.mask} ${className}`}
    onClick={onClose}
  />
);

export default Mask;
