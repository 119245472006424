import { FC, useRef } from "react";

import styles from "./index.module.css";
import buttonStyles from "../../../../../../Components/Common/Button/index.module.css";
import { useTranslations } from "../../../../../../translations/Context";
import { ITranslation } from "../../../../../../translations/types";
import AddPhoto from "../AddPhoto";

export type IAddFirstPhotoTranslations = {
  addPhoto: ITranslation;
};

type Props = {
  addPhoto: (result: string) => void;
};

const buttonClassName =
  `${buttonStyles.common} ` + `${buttonStyles.white} ` + `${styles.wrapper}`;

const AddFirstPhoto: FC<Props> = ({ addPhoto }) => {
  const addPhotoButtonRef = useRef<HTMLButtonElement>(null);
  const translations = useTranslations(
    "addFirstPhoto"
  ) as IAddFirstPhotoTranslations;
  return (
    <div
      className={buttonClassName}
      onClick={(event) => {
        event.stopPropagation();
        addPhotoButtonRef.current?.click();
      }}
    >
      {translations.addPhoto}
      <AddPhoto
        addPhoto={addPhoto}
        className={styles.addPhoto}
        innerRef={addPhotoButtonRef}
      />
    </div>
  );
};

export default AddFirstPhoto;
