import { FC } from "react";

import { AxiosResponse } from "axios";

import styles from "./index.module.css";
import NextButton from "./Next";
import PreviousButton from "./Previous";
import { IStep } from "..";
import { IAddress } from "../../../../../types";

type Props = {
  billingAddress: IAddress;
  email: string;
  isLoading: boolean;
  isSameBillingAddress: boolean;
  onPayment: () => Promise<AxiosResponse<string>>;
  setIsLoading: (isLoading: boolean) => void;
  setMessage: (message: string | null) => void;
  setStep: (step: IStep) => void;
  shippingAddress: IAddress;
  step: IStep;
};

const Buttons: FC<Props> = ({
  billingAddress,
  email,
  isLoading,
  isSameBillingAddress,
  onPayment,
  setIsLoading,
  setMessage,
  setStep,
  shippingAddress,
  step,
}) => (
  <div className={styles.wrapper}>
    <PreviousButton
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      setStep={setStep}
      step={step}
    />
    <NextButton
      billingAddress={billingAddress}
      email={email}
      isLoading={isLoading}
      isSameBillingAddress={isSameBillingAddress}
      onPayment={onPayment}
      setIsLoading={setIsLoading}
      setMessage={setMessage}
      setStep={setStep}
      shippingAddress={shippingAddress}
      step={step}
    />
  </div>
);

export default Buttons;
