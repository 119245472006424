import { FC } from "react";

import Device from "./Device";
import styles from "./index.module.css";
import { roundTwoDigits } from "../../utils";

type Props = {
  className?: string;
  price: number;
};

const Price: FC<Props> = ({ className = "", price }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div>{roundTwoDigits(price)}</div>
      <div>
        <Device />
      </div>
    </div>
  );
};

export default Price;
