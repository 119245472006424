import { FC, ReactNode } from "react";

import styles from "./index.module.css";
import { ITranslation } from "../../../translations/types";

type Props = {
  children: ReactNode;
  className?: string;
  title: ITranslation;
};

const Section: FC<Props> = ({ children, className, title }) => (
  <div className={`${styles.wrapper} ${className}`}>
    <div className={styles.title}>{title}</div>
    <div className={styles.children}>{children}</div>
  </div>
);

export default Section;
