import { FC } from "react";

import { useOutletContext } from "react-router-dom";

import { IUserDashboardContext } from "../..";
import Section from "../../../../Components/Dashboard/Section";
import { useTranslations } from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";

export type IFavoritesTranslations = {
  noFavorites: ITranslation;
  title: ITranslation;
};

const Favorites: FC = () => {
  const {
    user: { favorites },
  } = useOutletContext() as IUserDashboardContext;
  const translations = useTranslations("favorites") as IFavoritesTranslations;

  return (
    <Section title={translations.title}>
      {!favorites?.length
        ? translations.noFavorites
        : favorites.map((favorite) => (
            <div key={favorite._id}>{favorite.name}</div>
          ))}
    </Section>
  );
};

export default Favorites;
