import { FC } from "react";

import { Outlet } from "react-router-dom";

import EnvironmentContextProvider from "../../contexts/environment/Context";

const Shop: FC = () => (
  <EnvironmentContextProvider>
    <Outlet />
  </EnvironmentContextProvider>
);

export default Shop;
