import isEqual from "lodash/isEqual";

import { ICart, ICartItem, ISize } from "../../../types";

export const CART = "CART_STORAGE";
export const CART_EDITION_EVENT = "CART_EDITION_EVENT";

export const areItemsEqual = (item1: ICartItem, item2: ICartItem): boolean =>
  isEqual(
    { id: item1._id, size: item1.size },
    { id: item2._id, size: item2.size }
  );

export const createCart = ({
  items,
  updateDate,
}: {
  items: ICartItem[];
  updateDate?: Date;
}): ICart => ({
  items,
  updateDate: updateDate || new Date(Date.now()),
});

const DEFAULT_CART = createCart({ items: [] });

export const clearCart = () => {
  localStorage.removeItem(CART);
  window.dispatchEvent(new Event(CART_EDITION_EVENT));
};

export const getCart = (): ICart => {
  const storageCart = localStorage.getItem(CART);
  if (storageCart) {
    return JSON.parse(storageCart);
  }
  return DEFAULT_CART;
};

export const getCartItems = (): ICartItem[] => getCart().items;

export const getCartItemsCount = (): number =>
  getCartItems().reduce((count, item) => count + item.quantity, 0);

const setCartToLocalStorage = (cart: ICart) => {
  localStorage.setItem(CART, JSON.stringify(cart));
  window.dispatchEvent(new Event(CART_EDITION_EVENT));
};

export const setNewCart = (items: ICartItem[]) =>
  setCartToLocalStorage(createCart({ items }));

export const editQuantity = ({
  item,
  quantity,
}: {
  item: ICartItem;
  quantity: number;
}): ICartItem[] =>
  getCartItems().map((currentItem) => {
    if (!areItemsEqual(currentItem, item)) {
      return currentItem;
    }
    return {
      ...item,
      quantity,
    };
  });

export const editSize = ({
  item,
  size,
}: {
  item: ICartItem;
  size: ISize;
}): ICartItem[] =>
  getCartItems()
    .map((currentItem) => {
      if (!areItemsEqual(currentItem, item)) {
        return currentItem;
      }
      return {
        ...item,
        size,
      };
    })
    .reduce((previous, next) => {
      const previousItem = previous.find((current) =>
        areItemsEqual(current, next)
      );
      if (previousItem) {
        return previous.map((current) => {
          if (areItemsEqual(current, next)) {
            return { ...current, quantity: current.quantity + next.quantity };
          }
          return current;
        });
      }
      return [...previous, next];
    }, [] as ICartItem[]);

export const addItemToCart = (toAddItem: ICartItem): ICartItem[] => {
  const cartItems: ICartItem[] = getCartItems();

  const inCartItem = cartItems.find((currentItem) =>
    areItemsEqual(currentItem, toAddItem)
  );
  if (inCartItem) {
    return editQuantity({
      item: toAddItem,
      quantity: inCartItem.quantity + toAddItem.quantity,
    });
  }
  return [...cartItems, toAddItem];
};

export const emptyCart = () => {
  setCartToLocalStorage(createCart({ items: [] }));
};

export const removeItemFromCart = (item: ICartItem) =>
  getCartItems().filter((currentItem) => !areItemsEqual(currentItem, item));

export const getItemsPrice = (items: ICartItem[]): number =>
  items.reduce((price, item) => price + item.price * item.quantity, 0);

export const getCartPrice = () => getItemsPrice(getCartItems());
