import { FC } from "react";

import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

import { IPaymentStepTranslations, IStep, ORDERED_STEP } from "..";
import Button from "../../../../../Components/Common/Button";
import { useTranslations } from "../../../../../translations/Context";

type Props = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setStep: (step: IStep) => void;
  step: IStep;
};

const PreviousButton: FC<Props> = ({
  isLoading,
  setIsLoading,
  setStep,
  step,
}) => {
  const translations = useTranslations(
    "paymentStepTranslations"
  ) as IPaymentStepTranslations;

  if (ORDERED_STEP.indexOf(step) === 0) {
    return null;
  }

  return (
    <Button
      buttonType="white"
      disabled={isLoading}
      isLoading={isLoading}
      onClick={() => {
        setIsLoading(true);
        setStep(ORDERED_STEP[ORDERED_STEP.indexOf(step) - 1]);
      }}
    >
      <ArrowLeftOutlined />
      {translations[ORDERED_STEP[ORDERED_STEP.indexOf(step) - 1]]}
    </Button>
  );
};

export default PreviousButton;
