import { FC, ReactNode } from "react";

import { Link as ReactRouterLink } from "react-router-dom";

import { useLanguageContext } from "../../../translations/Context";
import Button, { IButtonType } from "../Button";

type Props = {
  buttonType?: IButtonType;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  route: string;
};

const Link: FC<Props> = ({
  buttonType = "white",
  children,
  className,
  onClick,
  route,
}) => {
  const { language } = useLanguageContext();
  return (
    <ReactRouterLink
      className="link"
      onClick={onClick}
      to={`/${language}/${route}`}
    >
      <Button
        buttonType={buttonType}
        className={className}
      >
        {children}
      </Button>
    </ReactRouterLink>
  );
};

export default Link;
