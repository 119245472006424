import { FC, useEffect, useState } from "react";

import ShoppingCartOutlined from "@ant-design/icons/ShoppingCartOutlined";

import styles from "./index.module.css";
import { CART, SHOP } from "../../../App";
import { ICartTranslations } from "../../../Routes/Shop/Cart";
import {
  CART_EDITION_EVENT,
  getCartItemsCount,
} from "../../../Routes/Shop/Cart/utils";
import { useTranslations } from "../../../translations/Context";
import Link from "../Link";

const Cart: FC = () => {
  const translations = useTranslations("cart") as ICartTranslations;
  const [itemsNumber, setItemsNumber] = useState<number>(getCartItemsCount());

  useEffect(() => {
    const onCartEdition = () => setItemsNumber(getCartItemsCount());

    window.addEventListener(CART_EDITION_EVENT, onCartEdition);

    return () => {
      window.removeEventListener(CART_EDITION_EVENT, onCartEdition);
    };
  }, []);

  return (
    <Link
      className={styles.wrapper}
      icon={<ShoppingCartOutlined />}
      isShowingText={false}
      route={`${SHOP}/${CART}`}
      text={`${translations.title}`}
    >
      <div className={styles.itemsNumber}>{`(${itemsNumber || 0})`}</div>
    </Link>
  );
};

export default Cart;
