import { FC } from "react";

import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import styles from "./index.module.css";

const LoadingPage: FC = () => (
  <div className={styles.wrapper}>
    <LoadingOutlined className={styles.spinner} />
  </div>
);

export default LoadingPage;
