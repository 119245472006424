import { FC, useState } from "react";

import uniq from "lodash/uniq";

import styles from "./index.module.css";
import Button from "../../../../../../Components/Common/Button";

type Props = {
  availableSizes: string[];
  setAvailableSizes: (sizes: string[]) => void;
};

const SizeInput: FC<Props> = ({ availableSizes, setAvailableSizes }) => {
  const [toAddSize, setToAddSize] = useState<string>("");

  const setNewSizes = () => {
    if (toAddSize) {
      setAvailableSizes(uniq([...availableSizes, toAddSize]));
      setToAddSize("");
    }
  };

  return (
    <div className={styles.wrapper}>
      <input
        onChange={(event) => setToAddSize(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setNewSizes();
          }
        }}
        value={toAddSize}
      />
      <Button
        buttonType="text"
        className={styles.plus}
        disabled={!toAddSize}
        onClick={setNewSizes}
      >
        &#xFF0B;
      </Button>
    </div>
  );
};

export default SizeInput;
