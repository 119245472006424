import { FC } from "react";

import { Link } from "react-router-dom";

import styles from "./index.module.css";
import { ADMIN, CREATE_ITEM, DASHBOARD, ITEM } from "../../../../App";
import Button from "../../../../Components/Common/Button";
import Section from "../../../../Components/Dashboard/Section";
import {
  useLanguageContext,
  useTranslations,
} from "../../../../translations/Context";
import { ITranslation } from "../../../../translations/types";
import { IItem } from "../../../../types";

export type IAddNewItemTranslations = {
  addItem: ITranslation;
  myItems: ITranslation;
};

type Props = {
  items: IItem[];
};

const Items: FC<Props> = ({ items }) => {
  const { language } = useLanguageContext();
  const translations = useTranslations("addNewItem") as IAddNewItemTranslations;
  return (
    <Section title={translations.myItems}>
      <Button
        buttonType="white"
        className={styles.item}
      >
        <Link
          className="link"
          to={`/${language}/${ADMIN}/${DASHBOARD}/${ITEM}/${CREATE_ITEM}`}
        >
          <div className={styles.image}>&#xFF0B;</div>
          {translations.addItem}
        </Link>
      </Button>
      {items.map((item) => (
        <Button
          buttonType="white"
          className={styles.item}
          key={item._id}
        >
          <Link
            className={"link"}
            to={`/${language}/${ADMIN}/${DASHBOARD}/${ITEM}/${item._id}`}
          >
            <img
              className={styles.image}
              src={item.images[0].url}
            />
            <div className={styles.label}>{item.name}</div>
          </Link>
        </Button>
      ))}
    </Section>
  );
};

export default Items;
